const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 1,
      searchType: 1,
      text: 'Customer Count by Day',
      seriesData: [
        {
          data: [
            120, 220,
          ],
        },
      ],
      legendData: [
        '25/Dec/2021',
        '26/Dec/2021',
      ],
      totalData: 27684,
      pieData: [
        { value: 15153, name: '25/Dec/2021' },
        { value: 12531, name: '26/Dec/2021' },
      ],
      entranceSearch4Data: [
        {
          time: '25/Dec/2021',
          count: 123,
        },
        {
          time: '26/Dec/2021',
          count: 223,
        },
      ],
    },
    {
      id: 2,
      searchType: 2,
      text: 'Customer Count by Month',
      seriesData: [
        {
          data: [
            120, 1132,
          ],
        },
      ],
      legendData: [
        'Dec/2021',
        'Jan/2022',
      ],
      totalData: 28884,
      pieData: [
        { value: 16353, name: 'Dec/2021' },
        { value: 12531, name: 'Jan/2022' },
      ],
      entranceSearch4Data: [
        {
          time: 'Dec/2021',
          count: '471503',
        },
        {
          time: 'Jan/2022',
          count: '62223',
        },
      ],
    },
    {
      id: 3,
      searchType: 3,
      text: 'Customer Count by Year',
      seriesData: [
        {
          data: [
            120, 1132,
          ],
        },
      ],
      legendData: [
        '2021',
        '2022',
      ],
      totalData: 38884,
      pieData: [
        { value: 16353, name: '2021' },
        { value: 22531, name: '2022' },
      ],
      entranceSearch4Data: [
        {
          time: '2021',
          count: '571503',
        },
        {
          time: '2022',
          count: '62223',
        },
      ],
    },
    {
      id: 4,
      searchType: 4,
      text: 'Customer Flow By Hour(28/Dec/2021-3/Jan/2022)',
      seriesData: [
        {
          data: [
            120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130, 80, 70, 110, 130,
          ],
        },
      ],
      legendData: [
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
        '00:00',
        '01:00',
        '02:00',
      ],
      entranceSearch4Data: [
        {
          time: '08:00',
          count: '1,018',
          ratio: '0.93%',
        },
        {
          time: '09:00',
          count: '1,629',
          ratio: '1.49%',
        },
        {
          time: '10:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '11:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '12:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '13:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '14:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '15:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '16:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '17:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '18:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '19:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '20:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '21:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '22:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '23:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '00:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '01:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: '02:00',
          count: '5,466',
          ratio: '5%',
        },
        {
          time: 'Total',
          count: '109,256',
          ratio: '',
        },
      ],
    },
    {
      id: 5,
      searchType: 5,
      seriesData: [
        {
          name: 'Dec/2021',
          data: [
            120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130, 80, 70, 110, 130,
          ],
        },
        {
          name: 'Jan/2022',
          data: [
            130, 202, 151, 81, 72, 120, 135, 121, 210, 160, 90, 73, 100, 100, 83, 60, 100, 120,
          ],
        },
      ],
      legendData: [
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
        '00:00',
        '01:00',
        '02:00',
      ],
      entranceSearch4Data: [
        {
          time: '08:00',
          count: '1,018',
          count2: '1,018',
        },
        {
          time: '09:00',
          count: '1,629',
          count2: '1,018',
        },
        {
          time: '10:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '11:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '12:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '13:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '14:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '15:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '16:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '17:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '18:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '19:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '20:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '21:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '22:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '23:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '00:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '01:00',
          count: '5,466',
          count2: '1,018',
        },
        {
          time: '02:00',
          count: '5,466',
          count2: '1,018',
        },
      ],
    },
    {
      id: 6,
      searchType: 6,
      table1Data: [
        {
          time: '07:00-08:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '08:00-09:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '09:00-10:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '10:00-11:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '11:00-12:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '12:00-13:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '13:00-14:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '14:00-15:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '15:00-16:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '16:00-17:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '17:00-18:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '18:00-19:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '19:00-20:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '20:00-21:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '21:00-22:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '22:00-23:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '23:00-00:00',
          count1: '159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '00:00-01:00',
          count1: '159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '01:00-02:00',
          count1: '159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
      ],
      table1FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
          count11: '12%',
          count12: '12%',
          count13: '12%',
          count14: '12%',
          count15: '12%',
          count16: '12%',
          count17: '12%',
          count18: '12%',
          count19: '12%',
        },
      ],
      table2Data: [
        {
          time: '07:00-08:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '08:00-09:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '09:00-10:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '10:00-11:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '11:00-12:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '12:00-13:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '13:00-14:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '14:00-15:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '15:00-16:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '16:00-17:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '17:00-18:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '18:00-19:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '19:00-20:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '20:00-21:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '21:00-22:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '22:00-23:00',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '23:00-00:00',
          count1: '1,129',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '00:00-01:00',
          count1: '1,129',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: '01:00-02:00',
          count1: '1,129',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
      ],
      table2FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/entranceSearch4\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
