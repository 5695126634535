import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
    resource: 'Dashboard',
    action: 'read',
  },
]
