<template>
  <v-row class="px-2 ma-0">
    <!-- role filter -->
    <v-col
      cols="12"
      sm="6"
    >
      <v-select
        v-model="currentBrandId"
        label="プランド選択"
        :items="brandInfoList"
        item-text="brandName"
        item-value="brandId"
        outlined
        dense
        hide-details
        @change="changeBrand"
      ></v-select>
    </v-col>

    <!-- plan filter -->
    <v-col
      cols="12"
      sm="6"
    >
      <v-select
        v-model="currentStoreId"
        label="店舗選択"
        :items="storeInfoList"
        item-text="siteName"
        item-value="siteId"
        outlined
        dense
        hide-details
        @change="changeStore"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from './storeModule'
import { isEmpty, getSessionData } from '@/@core/utils'

const USER_APP_STORE_MODULE_NAME = 'app-brand'

export default {
  setup(props, context) {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // 拠点情報、ユーザ情報取得
    const ecryptedData = sessionStorage.getItem('brandInfoList')

    const brandInfoList = ref([])
    const storeInfoList = ref([])

    const currentBrandId = ref(0)
    const currentStoreId = ref(0)

    const domesticFlag = String(getSessionData('data').domesticFlag)
    if (domesticFlag === '1') {
      // 復号する
      const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
      brandInfoList.value = JSON.parse(decrypted.toString(crypto.enc.Utf8))
      currentBrandId.value = Number(sessionStorage.getItem('currentBrandId'))
      currentStoreId.value = Number(sessionStorage.getItem('currentStoreId'))
      storeInfoList.value = JSON.parse(sessionStorage.getItem('storeInfoList'))
    }

    // if (domesticFlag === '1') {
    //   // 復号する
    //   const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
    //   brandInfoList.value = JSON.parse(decrypted.toString(crypto.enc.Utf8))
    //   currentBrandId.value = Number(sessionStorage.getItem('currentBrandId'))
    //   currentStoreId.value = Number(sessionStorage.getItem('currentStoreId'))

    //   if (!isEmpty(sessionStorage.getItem('storeInfoList'))) {
    //     storeInfoList.value = JSON.parse(sessionStorage.getItem('storeInfoList'))
    //   }

    //   if (currentBrandId.value === 0) {
    //     currentBrandId.value = brandInfoList.value[0].brandId

    //     sessionStorage.setItem('currentBrandId', currentBrandId.value)
    //     sessionStorage.setItem('currentBrandName', brandInfoList.value[0].brandName)
    //     const brandId = currentBrandId.value
    //     const userId = String(getSessionData('data').id)
    //     store
    //       .dispatch('app-brand/fetchStoreInfo', {
    //         params: {
    //           userId,
    //           brandId,
    //         },
    //       })
    //       .then(res => {
    //         storeInfoList.value = res.data.result.data.storeData
    //         currentStoreId.value = storeInfoList.value[0].siteId
    //         sessionStorage.setItem('currentStoreId', currentStoreId.value)
    //         sessionStorage.setItem('currentStoreName', storeInfoList.value[0].siteName)
    //         sessionStorage.setItem('storeInfoList', JSON.stringify(storeInfoList.value))
    //       })
    //       .catch(error => {
    //         console.log(error)
    //         this.$router.push({ name: 'error-login' })
    //       })
    //   }
    // }

    // ブランド変更
    const changeBrand = e => {
      sessionStorage.setItem('currentBrandId', e)
      const brandId = e
      const loginUserId = String(getSessionData('data').id)
      store
        .dispatch('app-brand/fetchStoreInfo', {
          params: {
            loginUserId,
            brandId,
          },
        })
        .then(res => {
          storeInfoList.value = res.data.result.data.storeData
          currentStoreId.value = storeInfoList.value[0].siteId
          sessionStorage.setItem('currentStoreId', currentStoreId.value)
          sessionStorage.setItem('currentStoreName', storeInfoList.value[0].siteName)
          sessionStorage.setItem('storeInfoList', JSON.stringify(storeInfoList.value))

          const filteredData = brandInfoList.value.filter(item => item.brandId === e)

          sessionStorage.setItem('currentBrandName', filteredData[0].brandName)

          context.emit('enterShopSearch')
        })
        .catch(error => {
          console.log(error)
        })
    }

    // 店舗変更
    const changeStore = e => {
      sessionStorage.setItem('currentStoreId', e)

      const filtered = storeInfoList.value.filter(item => item.siteId === e)

      sessionStorage.setItem('currentStoreName', filtered[0].siteName)
      context.emit('enterShopSearch')
    }

    return {
      brandInfoList,
      storeInfoList,
      currentStoreId,
      currentBrandId,
      changeBrand,
      changeStore,
      isEmpty,
    }
  },
}
</script>
