<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
        </div>

        <date-picker
          v-if="$datePickerDisplayFlag === true"
          @dataSearch="search"
        ></date-picker>

        <date-range-picker
          v-if="$dateRangePickerDisplayFlag === true"
          @dataSearch="search"
        ></date-range-picker>

         <date-range-picker-domestic
          v-if="$domesticDatePickerDisplayFlag === true"
          @dataSearch="domesticSearchBtn"
        >
        </date-range-picker-domestic>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-domestic-sc
            v-if="$selectDomesticScDisplayFlag === true"
            @enterShopSearch="domesticSearch"
          ></app-bar-domestic-sc>
          <app-bar-sc v-if="$selectScDisplayFlag === true"></app-bar-sc>&nbsp;&nbsp;

          <!-- <app-bar-i18n></app-bar-i18n> -->
          <!-- <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher> -->
          <!-- <app-bar-notification></app-bar-notification> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>Copyright &copy; {{ new Date().getFullYear() }}
          <!-- <a
          href="https://i-nes.co.jp/"
          class="text-decoration-none"
        > I-NES Co.,Ltd. </a> -->
        I-NES Co.,Ltd.
        <span class="d-none d-md-inline">, All rights Reserved</span></span>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'

// App Bar Components
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import { mdiMenu, mdiHeartOutline } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { ref, watch } from '@vue/composition-api'
import AppBarSc from '@core/layouts/components/app-bar/AppBarSc.vue'
import AppBarDomesticSc from '@core/layouts/components/app-bar/AppBarDomesticSc.vue'
import DateRangePicker from '@/views/forms/form-elements/datepicker/DateRangePicker.vue'
import DatePicker from '@/views/forms/form-elements/datepicker/DatePicker2.vue'
import DateRangePickerDomestic from '@/views/forms/form-elements/datepicker/DateRangePickerJP.vue'
import eventBus from '@core/utils/eventBus'

/* import { constant } from '@core/utils/constant'
import crypto from 'crypto-js' */

export default {
  components: {
    LayoutContentVerticalNav,
    DateRangePicker,
    DateRangePickerDomestic,
    DatePicker,

    // App Bar Components
    // AppBarI18n,
    AppBarUserMenu,
    AppBarSc,
    AppBarDomesticSc,
  },
  setup() {
    const $vuetify = getVuetify()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    const search = () => {
      eventBus.$emit('peopleCountSearch')
    }

    const domesticSearch = () => {
      eventBus.$emit('enter-ShopSearch')
    }
    const domesticSearchBtn = () => {
      eventBus.$emit('enter-ShopSearch')
    }

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      searchFilterFunc,
      search,
      domesticSearch,
      domesticSearchBtn,
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
