import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

let existingAbility
const ecryptedData = sessionStorage.getItem('userAbility')

// 復号する
if (ecryptedData !== null) {
  const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
  existingAbility = JSON.parse(decrypted.toString(crypto.enc.Utf8))
}

export default new Ability(existingAbility || initialAbility)
