import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './apps'
import dashboard from './dashboard'
import pages from './pages'
import userInterface from './user-interface'
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'
import { isEmpty } from 'lodash'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: () => {
      let userData
      let data
      const userEcryptedData = sessionStorage.getItem('data')
      if (userEcryptedData !== null) {
        const userDataDecrypted = crypto.AES.decrypt(userEcryptedData, constant.secret)
        userData = JSON.parse(userDataDecrypted.toString(crypto.enc.Utf8))

        const userAbilityEcryptedData = sessionStorage.getItem('userAbility')
        const userAbilityDataDecrypted = crypto.AES.decrypt(userAbilityEcryptedData, constant.secret)
        const userAbilityData = JSON.parse(userAbilityDataDecrypted.toString(crypto.enc.Utf8))

        if (userData.role === 'admin') {
          data = { name: 'apps-exportCsv' }
        } else if (userData.domesticFlag === 1) {
          data = { name: 'apps-home' }
        } else {
          const filterList = userAbilityData.filter(
            item => (item.subject === 'Dashboard'),
          )

          if (isEmpty(filterList)) {
            data = { name: 'apps-entrance' }
          } else {
            data = { name: 'dashboard' }
          }
        }
      } else {
        data = { name: 'dashboard' }
      }

      return data
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'error-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/loginJP',
    name: 'auth-login-jp',
    component: () => import('@/views/LoginJP.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...dashboard,
  ...userInterface,
  ...apps,
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  console.log(to.path)

  // if (to.path.indexOf('/apps/gifPlayer') !== -1) {
  //   console.log(to.query.data)

  //   return next({ name: 'apps-gifPlayer', query: { param: to.query.data } })
  // }

  let userData
  const userEcryptedData = sessionStorage.getItem('data')
  if (userEcryptedData !== null) {
    const userDataDecrypted = crypto.AES.decrypt(userEcryptedData, constant.secret)
    userData = JSON.parse(userDataDecrypted.toString(crypto.enc.Utf8))
  }

  let ability
  const abilityEcryptedData = sessionStorage.getItem('userAbility')
  if (abilityEcryptedData !== null) {
    const abilityDecrypted = crypto.AES.decrypt(abilityEcryptedData, constant.secret)
    ability = JSON.parse(abilityDecrypted.toString(crypto.enc.Utf8))
  }

  const isLoggedIn = userData && sessionStorage.getItem('accessToken') && ability

  if (!canNavigate(to)) {
    console.log(to)

    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
