import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted'
import GifPlayer from 'vue-gifplayer'
import 'vue-gifplayer/src/gif.css'

Vue.use(Toasted, {
  position: 'top-center',
  duration: 3000,
  fullWidth: false,
})
Vue.use(GifPlayer)
Vue.config.productionTip = false

const GlobalData = new Vue({
  data: {
    $domesticDatePickerDisplayFlag: true, // 国内日付選択表示フラグ
    $dateRangePickerDisplayFlag: true, // 日付選択表示フラグ
    $selectScDisplayFlag: true, // SC選択表示フラグ
    $selectDomesticScDisplayFlag: true, // ブランド、店舗選択表示フラグ
    $datePickerDisplayFlag: true,
  },
})

Vue.mixin({
  computed: {
    // 各コンポーネント内の<template>タグ内でグローバル変数を参照するための処置
    $dateRangePickerDisplayFlag: {
      get() {
        return GlobalData.$data.$dateRangePickerDisplayFlag
      },
      set(newDateRangePickerDisplayFlag) {
        GlobalData.$data.$dateRangePickerDisplayFlag = newDateRangePickerDisplayFlag
      },
    },

    // 各コンポーネント内の<template>タグ内でグローバル変数を参照するための処置
    $selectScDisplayFlag: {
      get() {
        return GlobalData.$data.$selectScDisplayFlag
      },
      set(newSelectScDisplayFlag) {
        GlobalData.$data.$selectScDisplayFlag = newSelectScDisplayFlag
      },
    },
    $selectDomesticScDisplayFlag: {
      get() {
        return GlobalData.$data.$selectDomesticScDisplayFlag
      },
      set(newSelectDomesticScDisplayFlag) {
        GlobalData.$data.$selectDomesticScDisplayFlag = newSelectDomesticScDisplayFlag
      },
    },
    $domesticDatePickerDisplayFlag: {
      get() {
        return GlobalData.$data.$domesticDatePickerDisplayFlag
      },
      set(newDomesticDatePickerDisplayFlag) {
        GlobalData.$data.$domesticDatePickerDisplayFlag = newDomesticDatePickerDisplayFlag
      },
    },
    $datePickerDisplayFlag: {
      get() {
        return GlobalData.$data.$datePickerDisplayFlag
      },
      set(newDatePickerDisplayFlag) {
        GlobalData.$data.$datePickerDisplayFlag = newDatePickerDisplayFlag
      },
    },
  },
  methods: {
    // 各コンポーネント内の<script>タグ内でグローバル変数を参照するための関数
    $_dateRangePickerDisplayFlag() {
      return GlobalData.$data.$dateRangePickerDisplayFlag
    },
    $_setDateRangePickerDisplayFlag(newDateRangePickerDisplayFlag) {
      GlobalData.$data.$dateRangePickerDisplayFlag = newDateRangePickerDisplayFlag
    },
    $_selectScDisplayFlag() {
      return GlobalData.$data.$selectScDisplayFlag
    },
    $_setSelectScDisplayFlag(newSelectScDisplayFlag) {
      GlobalData.$data.$selectScDisplayFlag = newSelectScDisplayFlag
    },
    $_selectDomesticScDisplayFlag() {
      return GlobalData.$data.$selectDomesticScDisplayFlag
    },
    $_setSelectDomesticScDisplayFlag(newSelectDomesticScDisplayFlag) {
      GlobalData.$data.$selectDomesticScDisplayFlag = newSelectDomesticScDisplayFlag
    },
    $_domesticDatePickerDisplayFlag() {
      return GlobalData.$data.$domesticDatePickerDisplayFlag
    },
    $_setDomesticDatePickerDisplayFlag(newDomesticDatePickerDisplayFlag) {
      GlobalData.$data.$domesticDatePickerDisplayFlag = newDomesticDatePickerDisplayFlag
    },
    $_datePickerDisplayFlag() {
      return GlobalData.$data.$datePickerDisplayFlag
    },
    $_setDatePickerDisplayFlag(newDatePickerDisplayFlag) {
      GlobalData.$data.$datePickerDisplayFlag = newDatePickerDisplayFlag
    },
  },
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
