const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
const data = {
  users: [
    {
      id: 1,
      userId: 'Galen Slixby',
      password: 'Yotz PVT LTD',
      sc: 'editor',
    },
    {
      id: 2,
      userId: 'Halsey Redmore',
      password: 'Skinder PVT LTD',
      sc: 'Le Chan_Ha Dong',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------

mock.onGet('/apps/user/users').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', options = {}, sc = null } = config.params

  // eslint-disable-next-line object-curly-newline
  const { page = 0, itemsPerPage = 0 } = options
  /* eslint-enable */

  const queryLowered = q.toLowerCase()

  const filteredData = data.users.filter(
    user =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      user.userId.toLowerCase().includes(queryLowered) &&
      user.sc === (sc || user.sc),
  )
  /* eslint-enable  */

  return [
    200,
    {
      filteredData:
        itemsPerPage !== -1 ? filteredData.slice((page - 1) * itemsPerPage, page * itemsPerPage) : filteredData,
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/user/users').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)

  // Assign Status
  user.status = 'active'

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(e => e.id === userId)
  const user = data.users[userIndex]
  Object.assign(user, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (user) return [200, user]

  return [404]
})
