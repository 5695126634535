import mock from '@/@fake-db/mock'

/* eslint-disable global-require */
const data = {
  emails: [
    {
      id: 50,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'james25@gmail.com', name: 'Katie Brandt', avatar: require('@/assets/images/avatars/8.png') },
      subject: 'Bring smile discussion same boy include care.',
      cc: [],
      bcc: [],
      message:
        '<p>Guy national course pay small per. Commercial research lose key fight marriage. Young series raise degree foot degree detail number.\nCrime gas real pass white. Television success east.</p><p>Into miss knowledge result. Seat carry tax beat line. Amount language paper machine fly.\nMusic several common former. More mouth year site move hold. Billion material born news western late.</p><p>World them term identify. Rule southern condition thought. Article successful traditional friend.\nPhone financial skill theory.\nChange Mr experience. Everyone help structure much family.\nVoice general group likely.</p></br>',
      attachments: [
        {
          fileName: 'log.txt',
          thumbnail: require('@/assets/images/icons/file-icons/txt.png'),
          url: '',
          size: '5mb',
        },
        {
          fileName: 'performance.xls',
          thumbnail: require('@/assets/images/icons/file-icons/xls.png'),
          url: '',
          size: '10mb',
        },
      ],
      isStarred: true,
      labels: ['private', 'company'],
      time: '2021-07-14T12:42:22',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 49,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'jamesskinner@hotmail.com',
        name: 'Joshua Cline',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      subject: 'Magazine say side view.',
      cc: [],
      bcc: [],
      message:
        '<p>Campaign even order for color. Remember card return position white argue prepare. Case fill follow then condition investment why.\nCold son pattern wife. Child name interest company thought every federal. He catch daughter design.</p><p>Affect customer a. Which difficult science.\nReality whether what animal. Call report author against season heart.\nCatch have always source response your even. Person mother whether since clearly. Cut staff work the nothing.</p><p>Cell cover along school. Method option not why laugh. Nation medical thousand world rule.\nEvening fish rich sense create. Civil family particularly day machine free read. Interesting capital owner international nor condition.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['personal'],
      time: '2021-07-16T01:23:14',
      replies: [
        {
          id: 74474,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'teresa54@gmail.com',
            name: 'Brittany Young',
            avatar: require('@/assets/images/avatars/2.png'),
          },
          subject: 'The beat save none make sea large number.',
          cc: [],
          bcc: [],
          message:
            '<p>College before employee recognize. Teach central this interest service party section. Floor west break bit suggest ok everyone.\nPm quality school out form. Want case town individual.</p><p>Hundred a modern career whose know find responsibility. East option trouble next. Sport goal after race pull political common board.</p><p>Beat support exactly material fact benefit six. Time represent stuff forget plant pass team. Begin lot war field simple.\nBuilding development wear trip marriage. Economy speech be election arrive color next.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['company', 'private', 'personal', 'important'],
          time: '2021-07-21T18:43:19',
          replies: [],
          folder: 'inbox',
          isRead: false,
        },
        {
          id: 766,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'angelajimenez@yahoo.com',
            name: 'Emily Moore',
            avatar: require('@/assets/images/avatars/8.png'),
          },
          subject: 'Movement along college bad reality scientist.',
          cc: [],
          bcc: [],
          message:
            '<p>Goal reveal past rule arrive project performance. Learn despite the way. Operation within suggest glass beautiful always really.\nLanguage although cut network conference economy long. Forward us point meet. Sing buy central quality science.</p><p>Real keep material wind drive life. Job election to determine table within expert art.\nOften ten ask city. Memory to run market.\nMove theory contain good fire. Area walk position site would.</p><p>Seem response base question tough consumer another. Sit hard deep child operation institution. Charge child picture different sense.\nMedia remain could go eight different west. Thousand fly box else.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal'],
          time: '2021-07-07T22:12:32',
          replies: [],
          folder: 'spam',
          isRead: true,
        },
        {
          id: 3718436,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'alexanderpatel@yahoo.com',
            name: 'Andrew Cruz',
            avatar: require('@/assets/images/avatars/7.png'),
          },
          subject: 'Realize agree dark spring suddenly maintain level history.',
          cc: [],
          bcc: [],
          message:
            '<p>Actually and them time itself newspaper stand. Billion Republican manager little hot store. Pull issue many close by large seven.\nIt writer will concern community rate through factor. Reduce south director budget shake return.</p><p>Score event since campaign single conference significant. Design fall teacher.\nWhich themselves along that themselves activity.\nUntil nothing cold toward politics product. Rock enter in what option.</p><p>Relate authority agency claim protect. Task not wait respond week hotel.\nAt catch matter try boy why white physical. Section protect try kind few. Skin two author style.\nWestern simple instead strategy mention item suffer. Remain agree account.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['company', 'private'],
          time: '2021-07-18T19:27:18',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
      ],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 48,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'williamsstacey@yahoo.com',
        name: 'Jonathan Walker',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      subject: 'Then until task something before color impact now.',
      cc: [],
      bcc: [],
      message:
        '<p>Head claim property experience arm remain structure. Worry do science look oil easy. His whose want.\nHuge protect foot save century somebody future. Skin building truth along sing such read speech.\nRaise argue everything send.</p><p>While attorney to power card. Agent card big nothing. Wall behavior investment stay relate stage their. Carry full rather product arrive center when.</p><p>Law chance mention sound maintain expect whose. Treatment simply if power decide bar. Theory building laugh hand manager condition true.\nFoot few eat store environment that involve man. Into report player writer yourself.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company', 'private', 'important'],
      time: '2021-07-22T09:59:40',
      replies: [],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 47,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'lori82@gmail.com', name: 'Kevin Evans', avatar: require('@/assets/images/avatars/7.png') },
      subject: 'Shoulder science point show human black answer anything.',
      cc: [],
      bcc: [],
      message:
        '<p>Rate church step beat head class nor.\nLeg ten offer girl me teach. Quite could within. Bill civil situation to.\nDifference unit tax garden. Fine cause political center her. Design look free treat item ball.</p><p>Also night argue I explain time practice.\nCommercial example reveal window try door great material. Wear data loss. Visit prove either catch will.</p><p>Show young century between box. Statement go guess bad film.\nIdea voice by audience meet everyone next prove. Art leader minute build.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['personal'],
      time: '2021-07-09T15:02:15',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 46,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'crussell@hotmail.com', name: 'Alexa Burnett', avatar: require('@/assets/images/avatars/4.png') },
      subject: 'Want manager source car recognize character impact.',
      cc: [],
      bcc: [],
      message:
        '<p>Mr attorney role meeting enter.\nMajor serve night often. Region current nation.\nHear each knowledge today. Church positive let anyone hospital member difficult color. Product difference such sea view senior.</p><p>Home require nor material current. State probably customer size soldier music site.\nSeveral east when miss partner language hotel ask. She hold turn. Century general study radio.</p><p>Old community prevent. Subject minute song sport.\nCover woman born decision agree center cold.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['personal'],
      time: '2021-07-09T06:52:08',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 45,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'kkim@yahoo.com', name: 'Dominique Paul', avatar: require('@/assets/images/avatars/4.png') },
      subject: 'Level within enjoy baby.',
      cc: [],
      bcc: [],
      message:
        '<p>Opportunity meet author table pressure leader. Owner never investment recent nearly before. Whom ask road.\nBody attorney clear program tonight current. Name watch school hard fly.</p><p>People crime window talk. Cell should third have sit would.\nOccur hit take.\nFact go system really entire common. Fast organization could themselves continue. End ahead rather.</p><p>Action quickly hundred movie choice. Nice yes lose two. Stay practice section onto some firm little Republican.\nLarge fast politics what. Common price speak sign particularly answer. You simply certain which direction.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important', 'personal'],
      time: '2021-07-15T14:59:01',
      replies: [
        {
          id: 781,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'amyconner@hotmail.com',
            name: 'Michael Martinez',
            avatar: require('@/assets/images/avatars/3.png'),
          },
          subject: 'Recent seek particularly seem southern charge.',
          cc: [],
          bcc: [],
          message:
            '<p>Accept his citizen again anyone. Claim process watch.\nSeven court there. Local author line would real machine officer.\nPlant just benefit operation. Similar soldier wrong part hospital action drive.</p><p>Before throw enough goal different. Doctor remain Mrs political staff.\nSeem successful why check after best pass. Degree because prove church move center space often.</p><p>Might trade cell guess institution. Difference win again.\nCulture life car agency improve you. Thing also hold child apply south box appear. Education itself effort their.\nFast save pull deal his talk issue. Fall sport better step.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['important', 'personal'],
          time: '2021-07-14T21:30:32',
          replies: [],
          folder: 'inbox',
          isRead: true,
        },
        {
          id: 6933053,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'ivanguerrero@yahoo.com',
            name: 'Ashley Fuller',
            avatar: require('@/assets/images/avatars/4.png'),
          },
          subject: 'Difference owner claim student site property would.',
          cc: [],
          bcc: [],
          message:
            '<p>Hour town against move difference scene cause walk.\nAgreement bag accept society story generation.\nLike process floor lose.\nStop think work off once. Billion institution anyone stuff determine federal attention.</p><p>Notice ever same tonight away performance role increase. Continue best same candidate expect look. Feeling church whole case risk town boy language.\nManage may send rate among. Physical law risk final source. Matter star ago or at possible.</p><p>Hotel I energy piece drop. Learn southern by maintain often evening.\nLate rise husband top skin memory lot.\nTest compare strategy father. Everyone few actually this again minute become.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private'],
          time: '2021-07-11T10:46:10',
          replies: [],
          folder: 'draft',
          isRead: false,
        },
        {
          id: 8,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: { email: 'lmoreno@yahoo.com', name: 'Ashley Lewis', avatar: require('@/assets/images/avatars/4.png') },
          subject: 'Candidate available material away.',
          cc: [],
          bcc: [],
          message:
            '<p>Ten spend paper. Trial certain those. Make middle campaign might.\nEffect well accept great wall.\nSeem your at small. So his serious high center political man.</p><p>Guess staff argue ready trade whole including. Science four skill best level interesting prevent. Mind he recent another point understand.\nAsk daughter specific hot without body challenge. Official threat pretty left bar check believe bit.</p><p>Trouble result receive political.\nAvailable knowledge increase. Dog computer ability prove paper. Scientist either color capital fall do.\nShoulder bar small. Those thank beyond sea piece.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['important', 'personal'],
          time: '2021-07-02T03:06:42',
          replies: [],
          folder: 'inbox',
          isRead: true,
        },
        {
          id: 784835803,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: { email: 'mortiz@gmail.com', name: 'Bradley Hobbs', avatar: require('@/assets/images/avatars/7.png') },
          subject: 'Tend picture church team place show society.',
          cc: [],
          bcc: [],
          message:
            '<p>Republican risk window. Different speak prove college from push main.\nRegion experience field wind get choose. Away drug professional memory. Nation still best fact forget election smile. Sure ready security office question.</p><p>Appear civil appear movie space.\nAmount rule meet wide exactly theory be. Pretty Republican material human that. Page war fear pay.\nAgree fall investment red nothing go also. Expect join against threat and.</p><p>Serve writer leader room.\nPurpose high west lose firm. Mouth between myself get upon avoid power low.\nSurface particular be main yeah. Huge parent morning continue research.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal', 'private', 'important'],
          time: '2021-07-04T08:11:01',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
      ],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 44,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'ethan27@gmail.com', name: 'Daniel Sullivan', avatar: require('@/assets/images/avatars/7.png') },
      subject: 'Choose security yes relationship recognize consumer democratic international.',
      cc: [],
      bcc: [],
      message:
        '<p>Person whom reflect prove show.\nBreak exist which prepare. Collection she population understand result business ability site.\nFact figure recent population condition. What west grow food space former.</p><p>Individual catch management her skin bag specific. Order base project under. Minute watch continue relationship state continue this store.</p><p>Recent cut organization machine.\nEnter today growth five interest some. Million official middle space return. Second cold available seven behind protect owner.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important', 'personal'],
      time: '2021-07-12T02:53:08',
      replies: [
        {
          id: 23853,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'hubbardsharon@hotmail.com',
            name: 'Laura Dominguez',
            avatar: require('@/assets/images/avatars/8.png'),
          },
          subject: 'Daughter skill fact rise nice power.',
          cc: [],
          bcc: [],
          message:
            '<p>Concern enter model team want admit detail far. West TV themselves short friend agreement service.\nAccording toward free upon draw family state. Account or action president piece.\nCause part fight second. Natural international mean.</p><p>Lay nearly center hear ten season officer water. Pattern loss window follow sure line.\nGlass analysis seat have. Ok budget among moment sing four.</p><p>Product now material play pick deal determine suffer. Most second region represent.\nRich reduce evidence home nothing yeah pressure. Rule play between fast wrong place.\nEvidence color anything because. Wall start manage style central charge beyond.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['company', 'important'],
          time: '2021-07-12T20:13:42',
          replies: [],
          folder: 'spam',
          isRead: false,
        },
        {
          id: 317,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'jameslopez@yahoo.com',
            name: 'Christopher Farrell',
            avatar: require('@/assets/images/avatars/3.png'),
          },
          subject: 'Character fall follow.',
          cc: [],
          bcc: [],
          message:
            '<p>Hope bar civil. Final design section those.\nBrother sit many receive vote read large. Reflect evening man realize detail. Party yeah factor never guy.\nSouthern movement everything. Play although movie effect space front.</p><p>Front first say interesting million force issue so. Enjoy least Democrat strong dark. Parent business bill surface arrive daughter.\nUntil home successful might capital. Nearly issue free customer. Carry matter executive country human shake.</p><p>Key do choose however.\nDiscuss each police modern. Apply method speech population participant.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal'],
          time: '2021-07-22T15:28:46',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
      ],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 43,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'rmartin@gmail.com', name: 'Nicole Allen', avatar: require('@/assets/images/avatars/4.png') },
      subject: 'Ten store nature surface that seek black return.',
      cc: [],
      bcc: [],
      message:
        '<p>Recognize section different ground million. Source court seek street.\nScience thank two capital shoulder herself certainly. Individual hair general manager why.</p><p>Live hear lawyer quickly player system. American spend ok beautiful. Shoulder drug itself wrong partner event.\nInclude account water success political. Newspaper quality really road. Short maintain raise appear.</p><p>Move cultural others protect season he future. Argue glass loss whether available size apply government.\nFood hand night particular. Change few key would thus.\nGreen talk to improve miss.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important'],
      time: '2021-07-27T07:21:36',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 42,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'adkinsryan@yahoo.com', name: 'Karen Russell', avatar: require('@/assets/images/avatars/6.png') },
      subject: 'Along represent responsibility security he leg.',
      cc: [],
      bcc: [],
      message:
        '<p>Explain through thought forward daughter entire. Investment direction great writer thus blue provide.\nPersonal she community phone same. Remain religious meeting. Data personal meeting agreement style. Next time build.</p><p>Avoid board beautiful strong effect. We star fight quality stay sense soldier. Her social month. System professional social.\nYoung back including benefit position plan just. Line history sometimes check need remain make.</p><p>Radio should magazine yard ahead then. Student knowledge cover general use though.\nEnergy agree away team. Power whose music sort between man analysis. Boy election value.\nClearly law avoid dream. Would around role third seek world present.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['personal'],
      time: '2021-07-02T20:02:30',
      replies: [
        {
          id: 82117976,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'oblack@hotmail.com',
            name: 'Michael Mccarthy',
            avatar: require('@/assets/images/avatars/3.png'),
          },
          subject: 'Around impact point interest method.',
          cc: [],
          bcc: [],
          message:
            '<p>Blood power job common. Spring success arm article. Continue manager blue new enough business six difference.\nMe finish pick energy wear him home. If affect ready east. Light enter speech many off day answer.</p><p>Quality consider statement building suddenly poor. Indeed because image month charge pressure lawyer. Color lot subject leg.\nUs cold everybody clearly evening ago apply. Run between pull. Could amount policy think second take born draw.</p><p>Rest feel forget garden tough citizen him. Sign court point recent.\nClaim wide chair plant. Smile build everyone politics run.\nFactor trip personal.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private', 'important', 'company'],
          time: '2021-07-04T15:30:03',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
        {
          id: 3151,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'zjackson@hotmail.com',
            name: 'Don Stewart',
            avatar: require('@/assets/images/avatars/7.png'),
          },
          subject: 'Nation campaign still never church politics business.',
          cc: [],
          bcc: [],
          message:
            '<p>Leg simple region out compare include wide. Simply kid away person training how. Answer laugh build attention cell authority be.\nPolitical citizen soldier record score green consider. Catch result traditional debate subject finally security.</p><p>Model seek stand fish three. View might space.\nSection receive fire town prepare public camera order. Sometimes nice another realize level. Shake fill institution forward author matter same.</p><p>Too home after lay senior. Result agree strong finish should easy onto agreement. Size PM usually war recent raise tend use.\nWork section story six billion. Long would add film middle financial third. Citizen up debate room owner deal.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private', 'company'],
          time: '2021-07-08T17:55:49',
          replies: [],
          folder: 'spam',
          isRead: true,
        },
        {
          id: 600,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: { email: 'mary98@yahoo.com', name: 'Dana Harvey', avatar: require('@/assets/images/avatars/2.png') },
          subject: 'Both community term run maybe sort per.',
          cc: [],
          bcc: [],
          message:
            '<p>Watch great himself all. Court such building kid from region. Reveal team poor lawyer theory listen.\nSon participant very better. Bed city dog sign.\nBall despite player whatever whatever opportunity. Training social kitchen blood fly.</p><p>May hit expert last. Attention opportunity shoulder. Agency federal just candidate study long.\nNotice first work full write recognize probably. Once writer common low last.</p><p>Hour about entire material. Various from subject military read safe seat. Truth third spend hair role home. Any herself analysis pay.\nGame get class ever enter once its. Job street student ok.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['important', 'private', 'personal', 'company'],
          time: '2021-07-27T16:48:17',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
        {
          id: 23080678,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'ppineda@yahoo.com',
            name: 'Samantha Martin',
            avatar: require('@/assets/images/avatars/4.png'),
          },
          subject: 'Whether far ready success yes many window.',
          cc: [],
          bcc: [],
          message:
            '<p>Cause have like. Unit nearly view feeling arrive player. Nor officer she production fly nice begin value.\nBehavior trade focus any. Or economy information class blue school structure everything. Production white although her total natural space.</p><p>Recognize section and tend. Understand box option agency event drive window. Child himself during statement financial under. Drug daughter attention magazine window go red.</p><p>Because drop measure I significant. Fall type us a staff wind court. Student discuss pattern way.\nPlan should book. Lead decide radio ok foreign behavior bit style.\nHundred no dream smile. Whose put indeed medical.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal', 'company'],
          time: '2021-07-04T13:28:16',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
      ],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 41,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'julie16@yahoo.com', name: 'Tyler Hernandez', avatar: require('@/assets/images/avatars/3.png') },
      subject: 'Environment success however window student.',
      cc: [],
      bcc: [],
      message:
        '<p>While million social ball surface in late.\nBudget though five so fund purpose.\nBall understand effect teach. Find charge rich child. Do require laugh everybody interesting.</p><p>Season south town performance whole political thought box. Management try just president. Finish fish strong teach enter ahead.\nBehind unit difference expert position two. Let before account baby cut should TV. Explain effort realize need.</p><p>Even item or environment save ten prepare activity. Nearly become current.\nBed nature indicate discussion least career perhaps. Head must sure. Why sea around buy. Audience politics sell strong career.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important', 'private', 'company'],
      time: '2021-07-06T01:09:02',
      replies: [],
      folder: 'sent',
      isRead: true,
    },
    {
      id: 40,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'brownsandy@gmail.com', name: 'Michael Smith', avatar: require('@/assets/images/avatars/3.png') },
      subject: 'Miss strategy want author test.',
      cc: [],
      bcc: [],
      message:
        '<p>Hear college professor see agent believe easy. Front test big black shoulder although. Candidate skill every player pressure.\nMany six reason allow kitchen. Respond us bank idea treat sure stuff tonight.</p><p>Nothing stay medical strategy early position maybe buy. Turn board early. Particularly then care value should material.\nSong doctor phone offer. Lawyer fear say discussion result represent. Performance back when cover effort.</p><p>Determine huge with newspaper computer focus detail trouble. Move support strong certainly.\nPopulation administration thing fund push movie democratic community. Town next wonder.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['private', 'important'],
      time: '2021-07-05T16:18:51',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 39,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'marissa73@hotmail.com', name: 'James Russell', avatar: require('@/assets/images/avatars/3.png') },
      subject: 'Interview some and minute.',
      cc: [],
      bcc: [],
      message:
        '<p>Result last clearly should bad. Need management account other player. Time pressure beautiful teacher provide. Mouth senior explain official would exactly.</p><p>Management attack fight some item. Once century agent method section what. What their defense you. Factor civil significant enough plan different.</p><p>Body amount know condition own gas near state. Strong as black place service.\nSignificant all game. Drive assume from wear option.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company', 'private', 'important'],
      time: '2021-07-19T05:03:32',
      replies: [],
      folder: 'inbox',
      isRead: false,
    },
    {
      id: 38,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'brendajames@hotmail.com',
        name: 'Richard Spencer',
        avatar: require('@/assets/images/avatars/7.png'),
      },
      subject: 'Town baby them account house save prevent.',
      cc: [],
      bcc: [],
      message:
        '<p>Pattern sing wrong late north.\nDeal age risk yourself mission able car defense. Choice audience determine dream spend Congress. Mrs produce everyone who bed civil.</p><p>Forget top well little door at share. Money leg recently from make will radio.</p><p>Result plant rich tonight here discussion draw during. Population play serious their bill. Reduce industry right remember attorney them too.\nFirst once over yard. Standard so low.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['company', 'important'],
      time: '2021-07-16T20:40:12',
      replies: [
        {
          id: 1245629,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'kellyjones@yahoo.com',
            name: 'Mark Martinez',
            avatar: require('@/assets/images/avatars/5.png'),
          },
          subject: 'Movement risk cultural.',
          cc: [],
          bcc: [],
          message:
            '<p>Both statement now painting decade guess commercial. Treatment movement over idea drop house expect. Heart sense agree live amount her.\nAuthority data Mr all day stock star. By shake seem shoulder not myself order. Out concern from reach.</p><p>Me worry field three name. Mr history when across around. Garden think rate central challenge guess structure.\nCall difficult relationship house around. Water public maintain. Our myself yet personal government condition.</p><p>Themselves final admit from staff conference no. Ask certain summer set purpose. Budget cost enter town most trip. Most your keep he the power.\nTrip news couple.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal'],
          time: '2021-07-11T06:55:40',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
        {
          id: 1,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'sonyamccall@hotmail.com',
            name: 'Lisa Richardson',
            avatar: require('@/assets/images/avatars/4.png'),
          },
          subject: 'Despite produce officer ground employee president.',
          cc: [],
          bcc: [],
          message:
            '<p>Understand conference debate. Among call fear away. Represent camera show job range street.\nInterview continue ahead believe subject. Himself sit them bit with bring. Oil particular represent wish home.</p><p>Your action note rise can food change. Eat claim plant accept wear film available few. Human wind security protect camera line.\nNotice deal to about truth forget every. Dark me camera where different better. Dog involve serve indicate do share for.</p><p>Sort all want oil travel need.\nBag contain hold deal individual pick believe ago. Middle oil receive close fact read. Offer often painting identify sure.\nLearn show next. Learn consider view face. Only life study near.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['important', 'private', 'company'],
          time: '2021-07-04T15:24:04',
          replies: [],
          folder: 'inbox',
          isRead: true,
        },
      ],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 37,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'juliasosa@hotmail.com', name: 'Cheryl Wright', avatar: require('@/assets/images/avatars/6.png') },
      subject: 'Movie admit final enjoy particular.',
      cc: [],
      bcc: [],
      message:
        '<p>Poor bad find. Report TV over long region defense.\nTwo sister according alone. Natural great before prove north assume become focus. Including work environment water poor.</p><p>Score though true evening again analysis feeling wait. Certain discover carry chance ever. Rich staff test raise discover.\nBoard federal improve bad impact eat box word. Situation blue culture environment road city soon.</p><p>Decade subject another our million or. Be stock interview easy those population maybe. Help send society. Win many team find.\nManagement about guy. Cultural resource prevent natural age tree reduce. Effect carry man.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important'],
      time: '2021-07-28T11:05:28',
      replies: [
        {
          id: 3558,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'dillon01@hotmail.com',
            name: 'Brenda Navarro',
            avatar: require('@/assets/images/avatars/8.png'),
          },
          subject: 'Business key Democrat sing.',
          cc: [],
          bcc: [],
          message:
            '<p>Meeting carry shake turn. Money because radio lawyer better. World trial view benefit result someone sort expert. American while public question.</p><p>Court ask various serious safe. Cup than hot child sort.\nSmile view issue high recently develop floor. Ten science including force message. Hear room author return risk military.</p><p>Unit vote popular collection strategy group. Newspaper region fly structure seem story art. Skill ever as money meet involve.\nAs environmental sister investment film represent. Until student occur include few science.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['important', 'personal'],
          time: '2021-07-20T02:27:59',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
        {
          id: 21238317,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'danielle69@yahoo.com',
            name: 'Erica Miller',
            avatar: require('@/assets/images/avatars/2.png'),
          },
          subject: 'Way program finish type yes then area.',
          cc: [],
          bcc: [],
          message:
            '<p>Project growth brother. Star capital bring.\nRange movement risk perhaps loss. Team raise card bag hit.\nParticularly last lead system within walk public perhaps. Tax travel suggest physical data company. Mrs fear establish away.</p><p>Treatment fight as foot Republican. Sister happy major I well less fish. Various goal face up. Age put head hotel style tree.\nSurface list evening this stay. Doctor stage would current. Wide audience after paper. Process yard end man future lead.</p><p>Moment push store necessary program. Have health seek. Name safe young career those agent.\nBe protect whatever skin. Read by talk we start. Might author final perform. Tv own follow wife either husband.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['company', 'private', 'important'],
          time: '2021-07-06T20:33:50',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
      ],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 36,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'benjamin30@gmail.com', name: 'Pamela Mueller', avatar: require('@/assets/images/avatars/6.png') },
      subject: 'Dinner start pretty.',
      cc: [],
      bcc: [],
      message:
        '<p>Require might team under authority.\nCustomer value still number deal. Cell both type customer do. Congress opportunity subject.</p><p>Above threat security how. Worry too interesting especially government help instead.\nWide ability study oil training teach. Help lot tree recent admit lot business.\nCapital order himself fall rest room those.</p><p>Impact beat business hear pretty. Current professor nearly agency. Attorney education fish result move.\nFormer military bar middle PM back his. Play nature image matter pick. Standard job smile food.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company', 'personal'],
      time: '2021-07-03T05:40:50',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 35,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'dana33@yahoo.com', name: 'Becky Coffey', avatar: require('@/assets/images/avatars/2.png') },
      subject: 'Less forget everything only girl.',
      cc: [],
      bcc: [],
      message:
        '<p>His exactly require able. Team become friend chair between within. Employee program power science eight guy dark.</p><p>Crime his teacher imagine outside energy recent. Building week short brother many enter measure. Approach better them area deep.\nChild gas yard character. To management mother never own arm key. Trouble three speech cover feel listen.</p><p>Future north quite partner interesting. Interview investment clear industry Democrat investment. Even ahead identify.\nThese character threat next help include. Offer contain necessary something all. Reflect growth quickly part rate create question.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important', 'company'],
      time: '2021-07-08T06:53:31',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 34,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'hollandjulie@yahoo.com',
        name: 'Christopher Evans DDS',
        avatar: require('@/assets/images/avatars/5.png'),
      },
      subject: 'Financial series artist region.',
      cc: [],
      bcc: [],
      message:
        '<p>Peace approach ask course central reality. Decision PM standard production brother report federal its. Wonder common group current often vote.</p><p>Professional sure fear blood much question. Operation ever authority water the woman. Hospital second rich let.\nOpportunity actually decision positive. During beautiful today decide know those. Chance list many create including become instead with.</p><p>Feel put treat. Mention arm name bank side.\nWhy area language reach well. Mother Mr worry order example.\nBegin part stay culture tend. Strategy administration yeah woman measure air. Than exist with indeed population talk.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['personal', 'private'],
      time: '2021-07-12T11:07:10',
      replies: [
        {
          id: 689385,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'wilsonroy@gmail.com',
            name: 'Chelsea Sims',
            avatar: require('@/assets/images/avatars/4.png'),
          },
          subject: 'Prove rest forward wear.',
          cc: [],
          bcc: [],
          message:
            '<p>Just trip own remember change these part. Trip success network send not room half yet. Floor pay which expert service.\nWhile both throw sister.\nCertainly remember certain country both. How seat exist. Hundred wind in page.</p><p>Trouble them least control. Forget up scene training garden. Effect for risk remain sign.\nSouthern bill blue general usually end how admit. Whom view final pay population reason. Type fire million on section individual.</p><p>Business specific prepare machine. Area stage poor pull. Performance myself dark school.\nScientist service student nation who wide market. Know clearly they finish. And maintain not soon play right.\nSign similar support cell. Meet less share pass.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal', 'company', 'private'],
          time: '2021-07-26T09:23:33',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
        {
          id: 66371,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'dannynguyen@hotmail.com',
            name: 'Matthew Schaefer',
            avatar: require('@/assets/images/avatars/5.png'),
          },
          subject: 'Staff can next along long true dark.',
          cc: [],
          bcc: [],
          message:
            '<p>Hotel only mind create soon north life. Improve pass too important those per including. System four north.\nFamily politics floor huge bad. Light look start apply forward civil agree. Later place expect at build.</p><p>First now against include time experience those and. Their these reveal guy dark. Always option fall evidence once success.\nLive sing gun meet. Spring face political voice. Blood clear couple run left available.</p><p>Visit network so total wife. People artist experience citizen maybe water good.\nHis news wonder note. Consumer kitchen him sport type.\nCandidate fall where structure. Art hour term matter look program.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal', 'private'],
          time: '2021-07-09T01:39:12',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
      ],
      folder: 'sent',
      isRead: true,
    },
    {
      id: 33,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'hsmith@gmail.com', name: 'Wendy Marshall', avatar: require('@/assets/images/avatars/8.png') },
      subject: 'Enjoy see man news decide build class make.',
      cc: [],
      bcc: [],
      message:
        '<p>Because gun area better region role party. Most cultural control radio religious rule human.\nFinal cold positive country story gun.\nThey myself bed involve. Course president health might lot close. Level fine college deal.</p><p>Tree race ground customer. Window prove maybe television possible well soldier over. International run conference free white audience consider.\nInterview ball leg number blood support his turn. Care product a.</p><p>Something ahead painting then option recognize. Use force price then away.\nFind agent hospital physical his. Town money person case during body.\nFast have kitchen character a race walk. Stage bring we entire sort.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['personal', 'company'],
      time: '2021-07-10T22:42:15',
      replies: [
        {
          id: 301809469,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'maryatkins@gmail.com',
            name: 'Kimberly Cisneros',
            avatar: require('@/assets/images/avatars/4.png'),
          },
          subject: 'Family pretty interest decision.',
          cc: [],
          bcc: [],
          message:
            '<p>Feeling production spend. Program look stand meet him. Ask away generation phone.\nMachine process window range serious process remain. Good charge in serious study seat. Heavy she concern door fire organization money fact.</p><p>Whether end investment pay. Happy information cup then. Edge fire suffer remain catch.\nDirector international determine might. Clearly fire something player. How that increase ready section. Visit become contain.</p><p>Decide find growth continue movie thank sort.\nPull where attention treat or. Since resource gas person trade organization crime. Growth southern lay lose president likely half.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['private'],
          time: '2021-07-23T04:23:43',
          replies: [],
          folder: 'spam',
          isRead: false,
        },
        {
          id: 930166,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: { email: 'nramirez@yahoo.com', name: 'Kenneth West', avatar: require('@/assets/images/avatars/1.png') },
          subject: 'Factor TV wife career thing loss increase.',
          cc: [],
          bcc: [],
          message:
            '<p>Every public quality also. Almost base imagine former decade pull also the. She stage so military admit.\nSouth better general base reason employee may. Control see way end material service. Everybody fear risk party weight.</p><p>Sea line production appear them through. Late gun something power little care. Interest since test total.\nProvide as condition none wind month thus. Fly sort south artist letter health night.\nWrong group affect even. Identify way interview politics.</p><p>Risk total natural follow music drop sense hospital. Space family cover effect. Live particularly letter generation toward concern reality friend.\nOrganization bar ask great most live seat week. Against western use present.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['private', 'company'],
          time: '2021-07-14T00:55:32',
          replies: [],
          folder: 'sent',
          isRead: false,
        },
        {
          id: 324,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'tina53@hotmail.com',
            name: 'Douglas George',
            avatar: require('@/assets/images/avatars/1.png'),
          },
          subject: 'Recognize to study.',
          cc: [],
          bcc: [],
          message:
            '<p>Perhaps pretty color walk different likely think. Southern occur soon chair leave discover heart. Rest product break member operation.</p><p>Agreement I include for.\nState anyone fight interview view west concern. Reach social reason how husband east.\nSometimes able especially simple size behavior. Talk beyond both big another often former.</p><p>Her money art involve building natural garden. Pay them respond step that. Old yourself table would agency. Pay recognize family individual.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal', 'company'],
          time: '2021-07-28T01:47:02',
          replies: [],
          folder: 'spam',
          isRead: true,
        },
      ],
      folder: 'spam',
      isRead: false,
    },
    {
      id: 32,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'jeffreystevens@yahoo.com',
        name: 'Christopher Adams',
        avatar: require('@/assets/images/avatars/3.png'),
      },
      subject: 'Finish actually parent condition business discussion later practice.',
      cc: [],
      bcc: [],
      message:
        '<p>Design left million test bag character. Pm everybody ago. Table finish sell my pay quite. Often account cover home war.\nCourt sport difference film left guy natural understand. Across ok quite now camera rock.</p><p>Plan citizen star off often evidence remember. Describe professor economic professional represent catch. Employee stand person eye. Region address spend.</p><p>It policy beyond scene. Wide bed culture account eat. Color technology even.\nMight ready option guess. Once create ever worker paper perhaps. Show likely say produce capital court.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['personal', 'important', 'private', 'company'],
      time: '2021-07-27T09:40:52',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 31,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'maria99@hotmail.com', name: 'Theresa Schmitt', avatar: require('@/assets/images/avatars/8.png') },
      subject: 'Life store technology least under black type.',
      cc: [],
      bcc: [],
      message:
        '<p>Main each pay bar professional blood fill. The commercial amount thousand carry. Sound ball become court relationship so white.\nFight late exactly evidence evidence art but. Congress spend camera sea other. Theory protect plant wait.</p><p>Her necessary capital around nor issue herself. Late quickly someone own painting moment participant.\nRequire civil night take. Southern cold because option report share fine who.</p><p>List black mean everything read front Mrs. Look whatever street approach fear guess once. Paper somebody hear machine.\nTogether it price world professor country. National worker specific shake. Open security tell all sure none imagine say.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company', 'private', 'personal'],
      time: '2021-07-01T03:23:03',
      replies: [],
      folder: 'sent',
      isRead: false,
    },
    {
      id: 30,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'kempsarah@gmail.com', name: 'Sherry Guzman', avatar: require('@/assets/images/avatars/2.png') },
      subject: 'Officer population memory level foot public.',
      cc: [],
      bcc: [],
      message:
        '<p>Summer general go happen owner last. Store live organization court think.\nDiscover second million today space activity conference. Generation young design factor interesting. Account always Mrs garden plant.</p><p>Sound discover piece people. Positive decade describe. Focus science free.\nSide mean however plan price me.\nBy later building result important down lay. Try growth structure nation above pull however those.</p><p>Wonder end value lead help quite trial. Recognize teacher establish explain. Try usually find over matter much.\nRaise son mouth.\nBase reach bit recognize focus. Stop best sea improve develop.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['private'],
      time: '2021-07-20T16:43:48',
      replies: [
        {
          id: 76,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'jessica23@gmail.com',
            name: 'Lauren Smith',
            avatar: require('@/assets/images/avatars/8.png'),
          },
          subject: 'College community effect care.',
          cc: [],
          bcc: [],
          message:
            '<p>Task age compare talk yard. Matter turn their price road.\nCulture four decide work chance cost include. Rock return statement. Major major several around method.\nUs would threat federal sense mean.\nCondition as why fast. Guy bit often professor.</p><p>Tell concern difference eye office trade fund fire. Lead report only star hot.\nFeel far factor current girl. Two hair fight a recent movie apply. Again series sometimes recent identify.</p><p>Perhaps agree note between house whom too. Down could important production tend figure special. West far bad impact cause great.\nRepresent green throughout never type trouble outside. Call adult would clearly. Turn stand federal.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private', 'important', 'personal'],
          time: '2021-07-20T17:21:18',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
        {
          id: 435260844,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'lkelley@yahoo.com',
            name: 'Michael Torres',
            avatar: require('@/assets/images/avatars/3.png'),
          },
          subject: 'Build learn audience water article ball must.',
          cc: [],
          bcc: [],
          message:
            '<p>Method election require important majority five. Seat listen story.\nCause middle act film. Available turn gun before whole especially kind simple.\nStage wrong hot find agree suddenly. Chance source clear share stay few.</p><p>Figure activity role official. Food live personal.\nPersonal no public computer prepare when. Fish available report network if attack among decide. Seem rule inside economic door.</p><p>Budget open send wrong property. Half spend stock less. Degree act general skin these any personal per.\nUntil never state chair already. Product sign best.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['important', 'company', 'private'],
          time: '2021-07-10T07:00:15',
          replies: [],
          folder: 'inbox',
          isRead: true,
        },
        {
          id: 7780,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'thomaspeterson@yahoo.com',
            name: 'Dylan Logan',
            avatar: require('@/assets/images/avatars/1.png'),
          },
          subject: 'Artist food section media commercial hospital.',
          cc: [],
          bcc: [],
          message:
            '<p>Tax above either world. Candidate accept final challenge which risk. Fact book example positive follow attention.\nCost building central contain natural. Adult least by.</p><p>Fast cause environment go explain necessary. Help citizen others beat sure child. Claim inside whether approach chance always central.\nSide ten bill look fine career. Attention real little power yourself bank.</p><p>Nothing American sister truth medical matter. Use door practice feel point fear. Argue else however involve fact.\nOwn recognize save. Federal brother loss mouth painting paper.\nDemocrat crime join quality. Off politics note soon.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private'],
          time: '2021-07-26T23:06:27',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
      ],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 29,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'daniel37@hotmail.com', name: 'David Cruz', avatar: require('@/assets/images/avatars/5.png') },
      subject: 'Clearly my usually billion ability response.',
      cc: [],
      bcc: [],
      message:
        '<p>Face despite management international talk force detail. Partner score hit democratic fast life property. Age information wear grow rise hard price. Every area character first activity smile.</p><p>Station character American usually nice change young. Make perhaps happy trade since science. Fine think attack successful.\nCrime bit spring city. Lawyer light ball unit instead statement. Lose friend account buy oil ten tend.</p><p>Security identify there. Person factor item build never language.\nEnter stock military early. Wish identify level difference fire wall. Girl finish sense indicate bad.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['private', 'company'],
      time: '2021-07-01T10:33:17',
      replies: [],
      folder: 'sent',
      isRead: true,
    },
    {
      id: 28,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'areeves@gmail.com', name: 'Anthony Obrien', avatar: require('@/assets/images/avatars/5.png') },
      subject: 'A type network effort blood do various.',
      cc: [],
      bcc: [],
      message:
        '<p>Own measure phone view baby officer. Detail nor television. Wear decade official long.\nCan interview point poor increase pick quickly run. General need audience foot weight firm. Month ability public. Go class let rise spring heart.</p><p>Cover attention letter later many town stuff away. Week lawyer western street.\nUnit rate reality adult. Arrive staff book me many.\nHand perhaps well thank join serious great budget. Including road upon will. Per price mission break.</p><p>Experience late nothing get baby head should. Must technology service address blood.\nChance decide else mean consumer pretty everything. Hospital couple second fly security region brother.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important', 'company'],
      time: '2021-07-20T18:39:49',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 27,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'bnunez@hotmail.com', name: 'Jason Gonzalez', avatar: require('@/assets/images/avatars/5.png') },
      subject: 'Affect method provide break himself house.',
      cc: [],
      bcc: [],
      message:
        '<p>Science design amount responsibility. Seem himself degree. Decade central manage. Rather four decide word.\nQuickly keep such popular different approach woman. Population body decade baby view significant can. Wish a build respond.</p><p>Site cut forget international lay he there. Tax early try authority.\nAbout term enjoy prevent affect. Even environmental kid skill.\nFirst plant number site bad interest board. Investment half so.</p><p>Method sea agent capital later just worker. Main guy cut build building. Condition similar best gun. Dinner new box major artist space in.\nRaise try science grow. House picture raise indeed light.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['private'],
      time: '2021-07-23T07:42:38',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 26,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'welchcrystal@gmail.com',
        name: 'Christopher Sanchez',
        avatar: require('@/assets/images/avatars/5.png'),
      },
      subject: 'Trade science concern necessary theory option us.',
      cc: [],
      bcc: [],
      message:
        '<p>Top foreign never recent baby girl base. Show charge senior difficult drug effect. Fear on standard doctor stop investment spring.</p><p>One long article market there into. Share nature member owner evening. Form tree real cultural.\nSecond be report teacher admit close.\nWhom skill teach. Blue song ahead weight rather walk line. Five talk require.</p><p>Rate onto nearly address rule side activity. Result ahead you hope woman worker evidence.\nCollection citizen we industry. Sister and that according organization leave. Day agency hope pick.\nEconomic him consider body four section single when.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important'],
      time: '2021-07-10T16:15:08',
      replies: [],
      folder: 'inbox',
      isRead: false,
    },
    {
      id: 25,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'fullerkatelyn@hotmail.com', name: 'Ana Short', avatar: require('@/assets/images/avatars/4.png') },
      subject: 'Follow tax officer soon our four relationship consumer.',
      cc: [],
      bcc: [],
      message:
        '<p>Couple almost table everyone together contain. Plan fill trip. Perhaps explain college will machine mouth training popular.\nNice include wrong road alone. Could for adult perform.</p><p>Tax ahead ground general industry. Else style only Mr agent all.\nAlready walk edge might forward. Cold wind hard read. Street poor process major especially example defense.\nDecade capital question talk work box forget. Always hear Mr ago.</p><p>Apply camera white natural should another. Past event herself score. Own thus general despite pattern. Ability pressure network mouth sometimes represent.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important'],
      time: '2021-07-12T09:22:10',
      replies: [
        {
          id: 4556357,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'woodjames@gmail.com',
            name: 'Taylor Lopez',
            avatar: require('@/assets/images/avatars/4.png'),
          },
          subject: 'Appear imagine western.',
          cc: [],
          bcc: [],
          message:
            '<p>Effect fall action chair candidate forward. Away character action start even focus claim address.\nJob once off according put off. Give answer near star cell expert. Use tax care month list.</p><p>Investment it check.\nPopulation oil mouth glass against. Stand all art leader agree.\nHerself only score image prevent bar table. Total treatment enjoy everything. Long later just cover or great meet.</p><p>Exist month watch wish remember simply low. Knowledge treatment maintain fine organization fall identify.\nIdea enough worry coach better stand general. Threat western language must person.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['important', 'company'],
          time: '2021-07-08T11:19:22',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
        {
          id: 419,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'charlotte46@gmail.com',
            name: 'Edwin Pena',
            avatar: require('@/assets/images/avatars/3.png'),
          },
          subject: 'Hundred happen national measure.',
          cc: [],
          bcc: [],
          message:
            '<p>Try high body design blue. Deep improve ahead police.\nHuman behind police international. Around would nor position particular physical break. Pm for against clearly.</p><p>Who in rock then build. Analysis produce kind senior until where. Part east understand.\nChance billion culture might so five. Particularly create story maintain article give fall. Short improve whatever new available wear affect.</p><p>Financial great impact everyone until.\nThem might try range main. Activity decade stock first stock start explain. Write phone nice increase fish several.\nNewspaper exist himself dinner choice agree hear. Great receive today identify.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['company', 'personal'],
          time: '2021-07-28T00:33:38',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
        {
          id: 5123,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'amandawagner@yahoo.com',
            name: 'Laura Montes',
            avatar: require('@/assets/images/avatars/2.png'),
          },
          subject: 'Administration choice move against provide value none.',
          cc: [],
          bcc: [],
          message:
            '<p>Goal throw including miss sometimes staff traditional. Material talk place point pay.\nShake popular part wind. While state light. Explain movement they.</p><p>Our herself indeed let use. Debate front within yes impact change big contain. Purpose outside nothing leg image never dark husband.\nPlant bring decision avoid ground act book. Up hold speech. Local indeed short.</p><p>Cold step herself style important. Week base tree game kid. Coach yet expect determine personal. Here happy peace have cause up.\nApply include recently reality common attention. Effort politics player though fly.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal', 'company'],
          time: '2021-07-23T04:17:17',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
        {
          id: 60679807,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'clarkdwayne@hotmail.com',
            name: 'Felicia Myers',
            avatar: require('@/assets/images/avatars/2.png'),
          },
          subject: 'Me during name.',
          cc: [],
          bcc: [],
          message:
            '<p>Miss back sing simply. Tax surface shake page so. Any rule vote for.\nSport six former simple. Daughter business push reality information.\nResource just possible rich enter. Tax full box beat. Network edge cultural among no morning.</p><p>Since but appear place. Trouble particularly paper chair commercial. Offer everyone success trip. Treatment special support resource.\nGun analysis test recently ball. Reality organization family test TV I surface.</p><p>Appear system shake charge nice foot. There our wrong author investment coach. Feel leg economy require push performance out speech. Need hair however commercial.\nLike Congress system whether skin. Research little attention art.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['company', 'important'],
          time: '2021-07-09T19:19:45',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
        {
          id: 31103,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'hobbsjeffrey@yahoo.com',
            name: 'Erica Mann',
            avatar: require('@/assets/images/avatars/6.png'),
          },
          subject: 'Ability pretty student health current interesting even.',
          cc: [],
          bcc: [],
          message:
            '<p>Image American daughter test animal. Somebody especially war loss name only just.\nStation such television also good away so water. Protect across television phone. Realize almost final half fight establish.</p><p>Program skill rest bed east here become law. How loss might purpose low time organization. Industry different enter share budget.\nFeel million how modern whole religious half finish. Hospital stage decision consider democratic.</p><p>Sort move scene behind. First office take together keep note break kind. Either laugh top agree prepare change.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal', 'important', 'company'],
          time: '2021-07-21T11:41:54',
          replies: [],
          folder: 'spam',
          isRead: false,
        },
      ],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 24,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'hmoran@gmail.com', name: 'Vincent Alexander', avatar: require('@/assets/images/avatars/1.png') },
      subject: 'Over tough city well first should quite.',
      cc: [],
      bcc: [],
      message:
        '<p>Sense speech economic compare chair. Suddenly model bank add. Let church door human ready share begin sense.\nPlay weight audience call necessary reach candidate rest. Collection lead voice position news listen police.</p><p>Describe safe almost hold. Rich because trip blue. Discussion born spend because anyone need.\nWonder skill state. Movie receive guess with. Turn pressure market term experience hotel collection.\nOff staff word once money.</p><p>Response north Mrs area writer election. Include early look similar nearly be. Rate happen green not.\nRun bed where state why sit house attorney. Which allow size learn. Describe mind where speak some son herself.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company', 'important'],
      time: '2021-07-12T13:33:33',
      replies: [
        {
          id: 324726,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'victorjohnson@yahoo.com',
            name: 'Tamara Vega',
            avatar: require('@/assets/images/avatars/6.png'),
          },
          subject: 'Democrat miss deal career maintain hotel.',
          cc: [],
          bcc: [],
          message:
            '<p>Base enter whom respond throughout together. Nor generation various company bar. What consumer how.\nKid recently civil store. High hot assume gun.</p><p>Important win election center. Party less knowledge only magazine past condition yard. Sound doctor say between.\nResult process may have firm wide. Moment audience skill safe fast. Spring although member defense value job.</p><p>Nothing serve media tell network site benefit artist. Left scene strong. As community decide major.\nNearly indeed send begin read. Recent foot three letter wide spend have. Growth whether once home actually without central.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['important', 'personal'],
          time: '2021-07-13T12:43:08',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
        {
          id: 3,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'jeremywilliams@yahoo.com',
            name: 'Jason Schultz',
            avatar: require('@/assets/images/avatars/1.png'),
          },
          subject: 'Piece effect usually everyone make.',
          cc: [],
          bcc: [],
          message:
            '<p>Market easy before really individual window soldier garden. Better space avoid fund. Politics friend class something western model. Seem successful recently sometimes.\nServe shake try for you our. Involve organization last at inside.</p><p>Employee office list player. Pass cold charge.\nEye sometimes article pressure. Chair mission structure him owner. Fight leg common her forget across against.\nMusic national student. At part wide fund.\nReady health everybody.</p><p>Cover century him back card property success. Enter feeling light oil cell push research.\nNow drop everyone must side blood program. Factor fire dark their kind hit everyone person. How property million interesting both important.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal'],
          time: '2021-07-13T19:56:30',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
        {
          id: 4,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'jasonpalmer@hotmail.com',
            name: 'Deborah Tran',
            avatar: require('@/assets/images/avatars/2.png'),
          },
          subject: 'Certain executive them health successful spring.',
          cc: [],
          bcc: [],
          message:
            '<p>Commercial individual understand past history large strong.\nPositive summer three need evening. North between pay politics art hand ago cover.\nLevel happen start practice reach. Produce sport show condition. Individual grow education.</p><p>Return fear food enter friend. Great company opportunity nearly garden choose.\nLast capital cell true edge. Daughter cost west stage force tell.\nEvidence stop whether power. North hospital base accept. Message him likely trouble tax business part.</p><p>Just record kind drug four perhaps entire. Economic surface century individual behind understand.\nTax hair charge investment similar perhaps pay. Return room create table other foot happen approach.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['private', 'personal'],
          time: '2021-07-14T18:37:56',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
        {
          id: 19865651,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: { email: 'danny06@gmail.com', name: 'Walter Moss', avatar: require('@/assets/images/avatars/3.png') },
          subject: 'Go town spend determine we money experience partner.',
          cc: [],
          bcc: [],
          message:
            '<p>Color onto chair very the account article different. Time however total without.\nHerself left knowledge never heart its product over. Citizen range state various same fall would day. Anyone against when grow evening.</p><p>Grow main front thing boy. Accept shake student consumer whom.\nAnyone return between apply.\nRead its prepare young. Week start for again focus doctor. Itself term until see somebody.</p><p>Trial direction idea green young. Success to light later.\nUse box sense indicate ask. Himself six five. Ready government than young represent difficult.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private'],
          time: '2021-07-08T13:58:13',
          replies: [],
          folder: 'spam',
          isRead: true,
        },
      ],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 23,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'paulbarrett@gmail.com', name: 'Robert Soto', avatar: require('@/assets/images/avatars/7.png') },
      subject: 'Apply loss always difference husband course deal.',
      cc: [],
      bcc: [],
      message:
        '<p>Realize American professor television give.\nNice meeting individual could major instead. Late development deep. Memory main how minute reduce want whether happy.</p><p>Capital fight water page artist seem own. Make join public break. Support water analysis cup forget together.\nAgain along listen defense ground mission once region. Last ground experience hot trade free camera.</p><p>Bill floor tonight good condition. Traditional must spring onto break. Left just everybody election. Treatment foreign control dark often.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['private', 'personal'],
      time: '2021-07-06T23:12:45',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 22,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'danagriffin@gmail.com',
        name: 'Alexander Alexander',
        avatar: require('@/assets/images/avatars/7.png'),
      },
      subject: 'Lead position story common choice pay sit line.',
      cc: [],
      bcc: [],
      message:
        '<p>Hand style bill phone day new area. Central husband measure could. Democratic health begin draw politics wear interest.\nHim avoid knowledge music. Offer forward happy easy. Just yard one light weight teacher threat.</p><p>American it feel parent protect. Center building recent politics when hand bar under. Without hard relationship issue.\nContinue friend game concern. Agency discussion simply hotel now prevent.</p><p>Sense indeed glass accept interest. Carry window dog onto involve specific.\nRadio despite police scientist economic. Fire affect your term. Send to end avoid political ability.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['private', 'personal'],
      time: '2021-07-03T07:04:27',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 21,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'bakercarrie@yahoo.com', name: 'Dawn Hall', avatar: require('@/assets/images/avatars/2.png') },
      subject: 'Magazine smile hear price.',
      cc: [],
      bcc: [],
      message:
        '<p>Ball skin product option anyone. Away involve whatever score.\nCommon ever show all body bed already. Modern politics century sort. Half study write life certain.</p><p>Nothing little whose carry source force heavy employee. Price force leave small follow. Push enjoy down teacher among. Huge nature whose risk season east maybe peace.\nPolitics interview drop sell. Trip from simple matter event.</p><p>Brother simply structure some kitchen some expect. Family personal civil focus professional task specific cut.\nDemocrat continue cause television yourself whether. Find west particular ago stand car.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important'],
      time: '2021-07-06T12:47:33',
      replies: [
        {
          id: 6333,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'james94@gmail.com',
            name: 'Ronald Mitchell',
            avatar: require('@/assets/images/avatars/1.png'),
          },
          subject: 'Cost example hope modern especially language rock.',
          cc: [],
          bcc: [],
          message:
            '<p>Mr go size financial role. Deal defense about space. Leader site water well side walk need.\nBall impact suddenly those rather have marriage first. May wear need may design.</p><p>Everyone artist run weight. State on executive travel.\nBrother instead nice while such half trial live. Policy truth animal make set them ask.\nPretty almost pick player after involve. Hot energy interview clearly however adult.</p><p>People during left particular rock design war young. Station require reflect. Later space head front within general. Program lose century stage.\nInstead very both. Owner bill tend Congress local.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private'],
          time: '2021-07-19T13:54:07',
          replies: [],
          folder: 'spam',
          isRead: true,
        },
        {
          id: 3539,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'averyamy@hotmail.com',
            name: 'Courtney Reynolds',
            avatar: require('@/assets/images/avatars/6.png'),
          },
          subject: 'President attack quickly religious.',
          cc: [],
          bcc: [],
          message:
            '<p>Stop military interest. Picture his money go quickly. Possible second wide high.\nTime air somebody on development born charge. Marriage address pull. Laugh chair range standard open list consumer wide.</p><p>Dinner another but student upon out. Soldier current management hair management.\nLikely population measure Democrat serious result reflect. Property tax knowledge. Recognize top peace nature pattern.</p><p>Table teach knowledge. Economic section security she. Myself share oil decide necessary when smile difference.\nService open oil car. Be model record stuff position scene also. All professional plan as radio candidate movie.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['company'],
          time: '2021-07-06T00:53:34',
          replies: [],
          folder: 'sent',
          isRead: false,
        },
        {
          id: 132667,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'englishjohn@gmail.com',
            name: 'Phillip Warner',
            avatar: require('@/assets/images/avatars/1.png'),
          },
          subject: 'Stand never treat commercial.',
          cc: [],
          bcc: [],
          message:
            '<p>Like begin million option dream just. Side still six truth alone exist that.\nIncluding himself movement increase significant. Police trial instead success he chair speak. Medical writer oil.</p><p>Successful compare analysis yes successful. Before sit old process similar physical.\nMedical receive debate than. Hit assume baby result place total.\nMoney discussion tax democratic surface everybody thousand. Throw six far home.</p><p>While reality along loss only alone pick current. Ok month view computer. Available drug ask knowledge add choose must.\nScene you ago laugh else city. Receive provide goal husband throughout. Focus local middle civil ever oil.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['important'],
          time: '2021-07-07T04:28:47',
          replies: [],
          folder: 'spam',
          isRead: false,
        },
        {
          id: 815966603,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'davidmckenzie@yahoo.com',
            name: 'Tony Garcia',
            avatar: require('@/assets/images/avatars/3.png'),
          },
          subject: 'However walk less use election.',
          cc: [],
          bcc: [],
          message:
            '<p>Shake stop century indicate cut garden. Night learn should low material north economy.\nAnother soldier base whole accept. Natural two everyone television. Sure option key market method week. Mouth day look too western world.</p><p>Company first rise in. Image movement enjoy clearly work box. Process parent fear state these theory want. Close friend team put check.\nCourt practice since account way indeed. Between exactly five. Conference green fast see century notice.</p><p>South six discover college long anyone young. Her company fine hotel rise.\nIf raise long yeah direction painting. Rest tell entire machine than summer laugh list. Personal rise figure collection player yard.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['private', 'company', 'important'],
          time: '2021-07-07T09:57:28',
          replies: [],
          folder: 'inbox',
          isRead: true,
        },
      ],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 20,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'sandraarroyo@hotmail.com',
        name: 'Natalie Lloyd',
        avatar: require('@/assets/images/avatars/2.png'),
      },
      subject: 'During teach truth group society enough that.',
      cc: [],
      bcc: [],
      message:
        '<p>Price agreement more tell. Push special fine turn alone.\nVarious weight shake heavy age control side so. Determine fall family agreement pull guy easy. Sell will director experience where challenge Democrat.</p><p>Fly such evening all entire. Data cold hour.\nLocal strong article tend bag. Probably relate political sell. Service end environmental theory health. Ready think body necessary low result impact.</p><p>Agency trial address per strong bill able. Top lay chair bag positive rich partner. Interest address government argue project attention myself election.\nReach value pattern treat act result star. Staff list federal.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company', 'important'],
      time: '2021-07-11T17:48:11',
      replies: [
        {
          id: 7,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'webersamuel@gmail.com',
            name: 'Steven Jackson',
            avatar: require('@/assets/images/avatars/1.png'),
          },
          subject: 'Hotel account interview begin carry everybody its.',
          cc: [],
          bcc: [],
          message:
            '<p>Get behavior better walk claim. Material popular civil detail.\nStop strong true first. Science scientist low story. These former near represent.</p><p>Fine value happy admit. Although its four could yet call. May beyond building bank push past perform.\nEnd civil audience son our my artist make. Security wish probably cold space reach life situation.</p><p>Station per choice live safe dog without. Above according break her woman organization market.\nCareer pass race mother manage for. Summer organization stage century fact individual particular.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal'],
          time: '2021-07-18T23:08:26',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
        {
          id: 378459327,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'jobush@yahoo.com',
            name: 'Mrs. Pamela Riggs MD',
            avatar: require('@/assets/images/avatars/6.png'),
          },
          subject: 'Industry difficult want without day partner road.',
          cc: [],
          bcc: [],
          message:
            '<p>Never hospital price site without star. Agency nature resource perhaps send. Stand nice must.\nProve window individual final. Exactly collection boy picture try operation increase. About purpose American type include store determine.</p><p>Speak they reality consumer ball church.\nWorld sit price. More local clear. Camera kind food.\nShe often term somebody prove. Would low over someone law.\nInstitution any among face begin race term do. Teach language technology get animal good.</p><p>Play cell type process certain total stay. Court enough side choice again speech.\nBy alone young scientist walk individual a. Mind relate whatever fund vote contain. Reflect special hospital study may local.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal', 'private'],
          time: '2021-07-18T03:59:12',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
      ],
      folder: 'spam',
      isRead: false,
    },
    {
      id: 19,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'washingtonsamantha@hotmail.com',
        name: 'Jessica Johnston',
        avatar: require('@/assets/images/avatars/6.png'),
      },
      subject: 'Exist general medical under entire radio.',
      cc: [],
      bcc: [],
      message:
        '<p>Process book suddenly plan sense change science. Prepare air option response. Voice range human.\nYet staff back idea note his cold. Raise service about state final official.\nHair when expect ok sit food. Religious rule doctor all.</p><p>Need improve field set wrong born.\nConsider there situation also something. Glass finally must special. Region news water responsibility to my short. Deal hotel fill.</p><p>Successful apply reality think woman short. Hope various indeed onto third audience.\nWay score none. Raise budget tough dinner name. Similar something fall certain I different.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['private'],
      time: '2021-07-24T18:10:41',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 18,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'mcleanrobert@hotmail.com',
        name: 'Matthew Lee',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      subject: 'Travel free or write determine.',
      cc: [],
      bcc: [],
      message:
        '<p>Edge memory where short stuff. Seven summer from sometimes body probably church.\nYeah might enough believe world person somebody. Compare summer road save magazine.</p><p>Light street wear home. Result baby my show current present. Attorney analysis rule democratic bed top.\nFace should pay side federal responsibility item. Test step safe his yourself.\nHold language interview other agency. Leg soon determine.</p><p>Make style already you physical.\nAir challenge fund dark. Myself another evening let big improve parent huge. Money fly investment practice.\nProvide feeling peace open decide course. Community attack her magazine white. Those let any beyond.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['private', 'company', 'personal'],
      time: '2021-07-24T00:15:10',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 17,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'perkinselizabeth@gmail.com',
        name: 'James Wilson',
        avatar: require('@/assets/images/avatars/7.png'),
      },
      subject: 'Always beautiful name push miss international.',
      cc: [],
      bcc: [],
      message:
        '<p>Skin if open line speak wish. Ten size their happen trial. Will third prevent.\nPopular wall indeed memory cause generation under age. Less one pressure guy song.\nUpon theory item science speak mission. After read plan official good week yet show.</p><p>Shake trip when once break election red. Left individual store site prepare figure. Once indicate blue wear effect person catch.\nWind chance entire perhaps carry notice leg. Successful property education. Guy option include.</p><p>Author of exist no bag exactly. To impact since.\nArgue market strategy evidence start business movie. Million fire crime magazine mention.\nDeep figure full Mr. Take response four serve law. Forward late part.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['important'],
      time: '2021-07-07T22:14:25',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 16,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'garcialauren@hotmail.com',
        name: 'Gregory Allen',
        avatar: require('@/assets/images/avatars/3.png'),
      },
      subject: 'Test look option movement position card cause.',
      cc: [],
      bcc: [],
      message:
        '<p>Key rather religious director week inside campaign. Sport fast activity.\nCamera go sing development up pay. Product toward well.\nRepresent appear civil skill son city leg. Best road attorney religious. Issue collection who peace morning director.</p><p>Above know trip beyond smile science. Part sport behavior notice establish. Recent direction similar everything admit pretty.\nBehind a knowledge second sound. Body soldier begin word site.</p><p>Sense policy rule after no response itself. Have magazine draw should bit often food. Car start that trade person.\nLeft pattern PM identify before executive Mr. State two your meeting task different.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['personal', 'company', 'important'],
      time: '2021-07-11T00:14:13',
      replies: [
        {
          id: 744639799,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'howardjustin@gmail.com',
            name: 'Rebecca Smith',
            avatar: require('@/assets/images/avatars/6.png'),
          },
          subject: 'Region stop vote tonight partner capital us.',
          cc: [],
          bcc: [],
          message:
            '<p>Republican ten picture although partner green.\nWrite his than another hand only. Focus night table speak ahead couple. Baby me single another already unit hand.</p><p>On alone involve.\nMusic author event story east pressure thus. Game power administration.\nNext standard boy provide although city short society. Hospital company old view.</p><p>Interest see majority ability center hope. His decision use most four return college. Born technology affect like.\nAlong your military there note great day attack. Specific I throughout. Hand month family open.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal', 'important', 'private'],
          time: '2021-07-05T08:12:17',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
        {
          id: 18,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: { email: 'dwelch@yahoo.com', name: 'Peter Davis', avatar: require('@/assets/images/avatars/1.png') },
          subject: 'Second ground way child seem social resource appear.',
          cc: [],
          bcc: [],
          message:
            '<p>Building believe manage analysis artist another enough similar.\nFood provide long view civil couple. Citizen too health west culture rule finish administration. Political ever eight message specific mission.\nServe determine city stand four present.</p><p>Moment compare red or institution begin more. Nothing law long might degree. Meet relationship work money human probably head.\nForward region their high with region their. Many side goal.</p><p>Customer thousand amount ask other might. Article energy wide relationship. Prevent save himself wrong action.\nShow entire play upon at shake. Unit heavy training window probably start share. Common by allow.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal'],
          time: '2021-07-23T16:37:03',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
        {
          id: 712,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: { email: 'larrybrown@yahoo.com', name: 'Amy Peters', avatar: require('@/assets/images/avatars/4.png') },
          subject: 'Interesting strategy south ok recognize shoulder lead.',
          cc: [],
          bcc: [],
          message:
            '<p>Bring dark let list then kitchen audience. Agreement raise result decision choose without.\nIndicate yet radio consider perform western. Find follow far require wish than pattern. Meeting benefit through seven service.</p><p>Question response big son student stuff. There imagine hold pick friend. For join condition try.\nAnimal foot work public one brother hit.\nWithout free business new degree. Local administration it those animal.</p><p>Simply less tax. Stuff apply member deal rather sort. Best politics project say rest.\nCare expect program break concern development care. East seat window. Kind firm cover up share perhaps.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal'],
          time: '2021-07-17T02:58:14',
          replies: [],
          folder: 'draft',
          isRead: true,
        },
      ],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 15,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'dreed@hotmail.com', name: 'Rhonda Hamilton', avatar: require('@/assets/images/avatars/4.png') },
      subject: 'They new police guy trade carry bad.',
      cc: [],
      bcc: [],
      message:
        '<p>Certain operation woman production especially second. To answer main good democratic move likely radio. Down rise human model land culture the.</p><p>Ten actually feeling call blue human. Less forward star another something he.\nUsually scene door enjoy heavy view management. Eye data conference. Attention traditional especially star else federal course. Speak position season stage head when.</p><p>Foot face beautiful little seven former you usually. Candidate hotel help.\nKitchen heavy she. Agent put move sister much. Hit some baby have fight.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company', 'private', 'important', 'personal'],
      time: '2021-07-18T12:06:21',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 14,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'andersonkatrina@hotmail.com',
        name: 'Richard Buckley',
        avatar: require('@/assets/images/avatars/7.png'),
      },
      subject: 'Hospital small technology defense affect car.',
      cc: [],
      bcc: [],
      message:
        '<p>Finish race write suggest visit pay east. Might point heavy care.\nSociety who happen stock over toward account. Question shake city share marriage drug.\nEvery test total agency another like. Wall day word camera art.</p><p>As thus necessary degree always support fall. Leader town agree improve check career. Later service when artist customer blood.\nEasy daughter tend no raise. Throw glass various among nearly act if. Than area sort trial many marriage old decision.</p><p>Worker coach together raise civil term themselves. Television something ok thank.\nAlmost song task there budget quite process than. Sell which apply environmental.\nDrop mind computer increase born.\nAuthor with will time. Garden others agency wall.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['personal', 'company', 'private'],
      time: '2021-07-09T06:36:05',
      replies: [],
      folder: 'sent',
      isRead: true,
    },
    {
      id: 13,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'kimberlyrobinson@hotmail.com',
        name: 'James Brady',
        avatar: require('@/assets/images/avatars/3.png'),
      },
      subject: 'There bar risk bring.',
      cc: [],
      bcc: [],
      message:
        '<p>Thought prepare want hand character design most. Run result attack before.\nVoice return give right way along. He lose change season less cell moment use. Today benefit would somebody.</p><p>Million area million across near company heart. Happen official knowledge look. Turn class interesting.\nGive product fund would factor into hope. Everyone painting program forget including.</p><p>Begin force foreign degree detail oil such.\nFirm scene individual here point. Particular interview before people last shoulder. Appear until spend under along magazine.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['personal'],
      time: '2021-07-06T02:27:43',
      replies: [],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 12,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'ugray@gmail.com', name: 'Jane Buckley', avatar: require('@/assets/images/avatars/2.png') },
      subject: 'Picture everything throw happen nothing social.',
      cc: [],
      bcc: [],
      message:
        '<p>Cell role hundred husband president figure. Make how real again.\nDevelopment image develop Republican. Military head drop. Relate wait able art.\nPolice response range establish back. Chance assume subject stock appear good research.</p><p>Thousand PM speech hear three yard should for.\nMachine crime too represent campaign book. According call each.\nPicture site create sister. Opportunity become who never bed number develop set. Major finish everyone meet vote letter across.</p><p>Reality send American. Democratic serious event oil lose. Tax position down front service improve election.\nThreat heavy over. Each leave several writer card politics. Question feel technology many thank.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important'],
      time: '2021-07-18T13:42:20',
      replies: [],
      folder: 'spam',
      isRead: false,
    },
    {
      id: 11,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'morrisjaclyn@gmail.com',
        name: 'Kathryn Smith',
        avatar: require('@/assets/images/avatars/6.png'),
      },
      subject: 'Green attorney government same course join in woman.',
      cc: [],
      bcc: [],
      message:
        '<p>Employee society live back. Bar woman film education.\nImportant report avoid. Wait nor goal. As morning say clear.\nBody strong of alone camera fall. Civil program particular first garden. Social become voice law quality.</p><p>Mouth whole for positive. Certain tough especially nature claim box.\nFill space allow second second cut. Bank want why decide recognize space.</p><p>Outside ability second whose second. Point stand bank list defense understand seat.\nClear finish follow media sing type. Technology white practice miss price.\nDifference establish some nation western job meeting. Give article beautiful.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['private'],
      time: '2021-07-08T14:51:49',
      replies: [
        {
          id: 133615687,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'juan31@gmail.com',
            name: 'Jennifer Robinson',
            avatar: require('@/assets/images/avatars/8.png'),
          },
          subject: 'Beautiful despite note couple pretty issue near.',
          cc: [],
          bcc: [],
          message:
            '<p>Store use cultural human smile. Subject trip that laugh.\nWalk sense a operation about window small southern. Show road them movement.</p><p>Water behind do else just. Reach mean science yet among what.\nGreen modern design us know use others weight. Recently wonder soldier within plan.\nRoom test story see southern special nice. Drop take mind plant throw American my. A husband sit thing.</p><p>There performance fine coach way majority truth. House beyond candidate beyond debate painting alone. There significant poor something chance spring. Yeah worry white Democrat.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private'],
          time: '2021-07-13T14:02:08',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
        {
          id: 867,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'gflores@hotmail.com',
            name: 'Cindy Hernandez',
            avatar: require('@/assets/images/avatars/2.png'),
          },
          subject: 'Watch vote decide compare start.',
          cc: [],
          bcc: [],
          message:
            '<p>Choice race different. Yard case newspaper wide series growth identify.\nBeyond go rest read me. Though quite industry method animal organization leave quality.</p><p>Back music theory fund produce. Foreign hard board learn home add. Data political buy budget think.\nBook consumer future writer. Bag evidence thus school.\nDifficult my accept yard. Million loss officer person language to. Television room feeling.</p><p>Country myself current tough image school. Court activity catch low value. Hotel local through.\nFocus attorney computer evening you always. Guess require event picture director. Garden floor month husband mention.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['company', 'important', 'private'],
          time: '2021-07-12T14:38:42',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
        {
          id: 7,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'judyvillarreal@hotmail.com',
            name: 'Amy Chavez',
            avatar: require('@/assets/images/avatars/8.png'),
          },
          subject: 'Member around task woman as.',
          cc: [],
          bcc: [],
          message:
            '<p>Police physical down generation condition throw foot relate. Table experience represent practice development.\nOrder option success thank miss. Tree knowledge light police service remain during. Entire respond join hit kind enjoy language.</p><p>Modern page social decide though small realize impact. Around special difficult level organization course her.\nMr tree three former this husband hold. Local expert especially should writer visit moment. Quite move travel less.</p><p>Nearly loss those democratic bring production. Ago economic method consider discuss.\nCapital approach red but reveal successful. Middle television treatment. Turn recent reflect interview.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['company', 'private'],
          time: '2021-07-17T02:32:58',
          replies: [],
          folder: 'spam',
          isRead: true,
        },
        {
          id: 59708653,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'mwalker@hotmail.com',
            name: 'Marcus Campbell',
            avatar: require('@/assets/images/avatars/3.png'),
          },
          subject: 'Woman ability middle choose vote few ability.',
          cc: [],
          bcc: [],
          message:
            '<p>Because structure put. Face business possible light box.\nSmile group six history financial. General try financial either discuss like million. Begin create fill series age.\nExist control popular begin deep. Sit another health live.</p><p>Politics side finally senior sit here activity protect. Heavy major control education. Bad involve want skill project feel.\nNone usually kid study eight. Civil consider effort. Marriage front their live eye significant far.</p><p>Scene keep major bank up prepare others. Change century brother media energy alone. Life range explain interest address.\nMedical account indicate hit start live support. Prove popular claim direction college.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['private'],
          time: '2021-07-04T03:37:29',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
        {
          id: 804622,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'lauramartin@hotmail.com',
            name: 'Connie Osborne',
            avatar: require('@/assets/images/avatars/8.png'),
          },
          subject: 'Heavy ball debate style message main rate.',
          cc: [],
          bcc: [],
          message:
            '<p>Feeling ability finish kitchen majority same moment. Decision money compare really education deal. Officer get be food ahead compare stay.\nDeep teacher state. Guy purpose too remain help enough.</p><p>Cut city father while green both information.\nLetter left fall body general. Very exactly common though policy star. Former health arm respond treatment.\nEnter industry will trouble day authority agree blood. Indeed air until but idea nor enter.</p><p>Site direction lay hotel these. Role focus affect focus before. Gas fill figure rise marriage like offer child.\nAgainst wall either. Mind one ready total. Fly food why part for again season.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['company', 'important', 'private'],
          time: '2021-07-02T03:33:03',
          replies: [],
          folder: 'sent',
          isRead: true,
        },
      ],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 10,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'sean36@gmail.com', name: 'Ronald Buckley', avatar: require('@/assets/images/avatars/5.png') },
      subject: 'Blue both light anyone trial nor approach tough.',
      cc: [],
      bcc: [],
      message:
        '<p>Take anything season ok. Nor than war fine speak happen. Where business hold continue message state for.\nMorning southern allow. Mission color camera how Republican behind. Learn five break suffer.</p><p>Over born sure continue. Option show meet however.\nModel no mean us. Enough as space herself article bring others. Place them need drive cost decide.</p><p>Million friend remain product eye Congress. Education near amount middle.\nSay key past if shoulder rule. Others mean behind case interesting bag near option. Step why example mean thus. Fish forget turn never kind boy anyone.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['personal', 'important'],
      time: '2021-07-11T11:09:30',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 9,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'wkline@yahoo.com', name: 'Jennifer Garcia', avatar: require('@/assets/images/avatars/6.png') },
      subject: 'Simply idea project health prevent beyond both after.',
      cc: [],
      bcc: [],
      message:
        '<p>Second again well doctor because election necessary point. Campaign about from western themselves particular loss popular. During garden star couple water simply area.</p><p>Worker leave know mission southern. Sea eye walk moment.\nCamera executive education wall marriage say. Man tend perform. Issue area great financial note other guess.</p><p>Likely market physical heavy quite we.\nRecent how room page sit fast Congress fight. Interview establish watch water.\nLoss family picture mind consumer about PM. Safe natural size. Character recognize painting movie.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['personal', 'company'],
      time: '2021-07-17T20:25:41',
      replies: [],
      folder: 'inbox',
      isRead: false,
    },
    {
      id: 8,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'tolson@gmail.com', name: 'Lindsey Melton', avatar: require('@/assets/images/avatars/2.png') },
      subject: 'Amount collection marriage price.',
      cc: [],
      bcc: [],
      message:
        '<p>Join list dog rate doctor surface share. Meeting beat particular sing apply space.\nClear down thought magazine meet.\nWould better sport wide personal matter. Analysis effort school officer such. Age blue future her start marriage.</p><p>Material year close beat rest happy. Interview material over thought. Win until morning certainly.\nDevelopment personal direction game present.</p><p>Accept wall price hair garden staff. Enough off rest. Beyond half small lay agency.\nOption in hand charge direction least message. Safe minute situation just floor. Guess month than already.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company'],
      time: '2021-07-25T05:19:46',
      replies: [],
      folder: 'inbox',
      isRead: false,
    },
    {
      id: 7,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'jeffrey89@gmail.com', name: 'Amanda Pratt', avatar: require('@/assets/images/avatars/4.png') },
      subject: 'Pull clear protect start exactly purpose scientist food.',
      cc: [],
      bcc: [],
      message:
        '<p>See beautiful necessary hold. Marriage TV cut look chance whom.\nHeavy girl like only special position hot throw.\nReligious someone value girl save avoid. Market soon against central baby. So follow paper run along bag.</p><p>Worry provide form. Walk receive adult.\nMind style campaign blood. Public sign allow history nature customer. Offer how answer join.\nDiscussion blue Congress half important beat without. Authority key personal forget quickly model quickly really.</p><p>Better know magazine. Attention discuss staff turn affect tough.\nSo later whose reveal follow. Almost someone end. Rate necessary dog strategy.\nHope administration born his. Upon foot vote ability medical. Poor behind stage opportunity.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company'],
      time: '2021-07-12T16:41:20',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 6,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'gentryjeff@yahoo.com', name: 'Joseph Clark', avatar: require('@/assets/images/avatars/3.png') },
      subject: 'Grow seat discover.',
      cc: [],
      bcc: [],
      message:
        '<p>Become laugh and up onto. Sister raise pretty material picture. Own middle region open.\nProcess rock throw kind.\nQuestion them interest some international notice agreement. Control remember purpose.</p><p>Level consumer contain process rise system. Ten responsibility finally detail development else.\nRace well letter. Over receive it might.\nDifferent use send than he everyone. Drive answer develop bad past budget.</p><p>Increase prove theory million lose down quickly.\nMoment young just position information.\nName discover different majority use seek. Religious world discover never pressure ok develop. Name also all. Drug city program way.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['personal', 'private'],
      time: '2021-07-20T13:44:07',
      replies: [],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 5,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'maciaspatricia@hotmail.com',
        name: 'Alisha Hughes',
        avatar: require('@/assets/images/avatars/4.png'),
      },
      subject: 'Play hope gas military that.',
      cc: [],
      bcc: [],
      message:
        '<p>International hundred anything see ten but long. Collection edge difference turn other let price. Would ahead commercial may scene develop minute.\nOnly film avoid. Last dark party store. Collection another three movement network ready hit.</p><p>Report keep probably individual argue.\nKid activity style million. Late stage lawyer answer.\nReligious both opportunity wide. Once television amount necessary so line. Now simple shoulder ground.</p><p>Radio idea glass realize research floor. Why range brother baby own impact century. Believe service doctor once.\nKnowledge finally anything sea. Across certainly reality provide. Past center feeling financial.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important'],
      time: '2021-07-04T09:53:05',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 4,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'rvalenzuela@hotmail.com',
        name: 'Michelle Murphy',
        avatar: require('@/assets/images/avatars/6.png'),
      },
      subject: 'Anyone want yet forget effect.',
      cc: [],
      bcc: [],
      message:
        '<p>Expert space school material success security interest. Realize size seem growth game evidence. Time itself fine travel.\nCup reason environmental analysis.</p><p>Chance election look. Pretty job they officer other.\nBrother challenge military dark. Decade behavior several few race ball along. Amount rich suddenly stand. Mention street local site.</p><p>Join thus employee determine degree lead player. Color room ever soon easy. Administration toward experience why.\nSea hard detail rule. Strong factor language enjoy find.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['company', 'important'],
      time: '2021-07-17T15:51:47',
      replies: [],
      folder: 'spam',
      isRead: false,
    },
    {
      id: 3,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'gbeltran@gmail.com', name: 'Charles Cooper', avatar: require('@/assets/images/avatars/1.png') },
      subject: 'Fight account night short.',
      cc: [],
      bcc: [],
      message:
        '<p>Get through stay. On difficult popular.\nFine turn mean artist. President explain turn professor fly prove cultural. Moment field front.\nSuccess almost various week. North message herself front eight. Final huge right happy.</p><p>Analysis rise son let. Age specific against visit.\nPerhaps series unit center total. Bed hour sense. Star morning history design late.\nOnce but fund share education. Majority face what year interest wish financial pretty.</p><p>Class treat enjoy stock seven natural establish indeed.\nHelp eat figure rich. Although bill discover build town.\nAsk continue claim here hand surface. Success foot action close treat.</p></br>',
      attachments: [
        {
          fileName: 'log.txt',
          thumbnail: require('@/assets/images/icons/file-icons/txt.png'),
          url: '',
          size: '5mb',
        },
        {
          fileName: 'performance.xls',
          thumbnail: require('@/assets/images/icons/file-icons/xls.png'),
          url: '',
          size: '10mb',
        },
      ],
      isStarred: true,
      labels: ['important', 'company'],
      time: '2021-07-22T19:12:31',
      replies: [
        {
          id: 756051771,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'rwhitehead@yahoo.com',
            name: 'Bruce Johnson',
            avatar: require('@/assets/images/avatars/3.png'),
          },
          subject: 'Guy someone wind.',
          cc: [],
          bcc: [],
          message:
            '<p>Century those system character. Enter mind she baby compare movie. Soldier reality guy end meeting go.\nPositive only our important. Month world century impact nothing such bar. Term their himself safe its deep.</p><p>Coach bank agent value glass race. Instead reason suffer bar role action finally town. Political market window of although least will.\nGuess thought chance term.</p><p>Pressure tonight beyond because wait early leader prove. Ground reality court event bar. Behind manage really so four vote.\nSecond series score thus.\nRealize move around baby interview clear.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal', 'private'],
          time: '2021-07-22T16:03:07',
          replies: [],
          folder: 'spam',
          isRead: true,
        },
        {
          id: 4255040,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'perezannette@gmail.com',
            name: 'Kyle Christensen',
            avatar: require('@/assets/images/avatars/7.png'),
          },
          subject: 'Each close probably.',
          cc: [],
          bcc: [],
          message:
            '<p>Late contain dream why ready go spring to. Against page medical wonder just fall card four. Unit live manager within feeling.\nSupport democratic lose list law. Baby address inside area or. Little individual remain sister area since thousand.</p><p>Culture effect similar clear population stuff himself quite. Trade story quality quite successful such.\nEven might his continue necessary thousand give. Record former tend determine true population reflect.</p><p>Dream when TV try loss central. Billion direction up run reduce that record. Ability then best draw.\nRich second yourself deep about foreign impact. Crime military appear shoulder bed. West job call home health woman lot.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['personal'],
          time: '2021-07-15T20:54:36',
          replies: [],
          folder: 'draft',
          isRead: false,
        },
        {
          id: 946586133,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'ramirezsarah@yahoo.com',
            name: 'Tammy Lloyd',
            avatar: require('@/assets/images/avatars/8.png'),
          },
          subject: 'Security set letter once.',
          cc: [],
          bcc: [],
          message:
            '<p>Hair kind piece main want evening career. Water artist source ago south design father. Mention movie number house yeah some government.\nScore rock idea seven establish of. Candidate oil fact about to spend about.</p><p>Not both energy key.\nMust face those idea address pull.\nLet look cover star place later. Personal student both window agency produce.\nRemember cause hour explain box worry. One upon might soon enter baby car consumer.</p><p>Character service your idea. Adult guess stay us. Law would improve.\nWithin official anyone Mr. Difference before record treatment perhaps audience culture. Along present experience because history challenge detail.</p></br>',
          attachments: [],
          isStarred: true,
          labels: ['company', 'important'],
          time: '2021-07-20T05:34:05',
          replies: [],
          folder: 'spam',
          isRead: true,
        },
        {
          id: 182449812,
          to: [{ email: 'johndoe@mail.com', name: 'me' }],
          from: {
            email: 'evansantonio@yahoo.com',
            name: 'Shawn Flores',
            avatar: require('@/assets/images/avatars/5.png'),
          },
          subject: 'Card yeah need shake.',
          cc: [],
          bcc: [],
          message:
            '<p>Fine wonder sister order rock conference lose should. Personal party drug sense way north. Hear stock political pick model.</p><p>Focus population expert sense past green. Call community property tough news instead bad. War explain former quite else explain next guy. Education like send method method.</p><p>Necessary detail teacher company discuss world activity. And me get star eat power. Read sound wish already culture seek because face. Attorney purpose green.</p></br>',
          attachments: [],
          isStarred: false,
          labels: ['personal', 'private'],
          time: '2021-07-22T14:31:03',
          replies: [],
          folder: 'trash',
          isRead: true,
        },
      ],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 2,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: {
        email: 'wilsonwilliam@yahoo.com',
        name: 'Rachel Palmer',
        avatar: require('@/assets/images/avatars/2.png'),
      },
      subject: 'Account base lose detail.',
      cc: [],
      bcc: [],
      message:
        '<p>Religious system evidence star meeting notice draw. Garden audience sometimes strong imagine vote free.\nLow Republican nice. Toward fund decade ever. Likely itself serve camera risk adult imagine.</p><p>Main nice environmental address defense. Toward movie inside every. Else event message continue.\nReturn rise attorney black role. Individual build tonight soldier return environment successful. Dinner learn rock mother wife all.</p><p>Yard but card her then. Foreign evening ability my president dog guess. Leave husband south.\nHealth leg represent yeah. Turn sell onto kid several. Morning degree few.\nStart dark measure big end role. Property attention walk eye exist.</p></br>',
      attachments: [],
      isStarred: false,
      labels: ['important'],
      time: '2021-07-10T01:13:20',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 1,
      to: [{ email: 'johndoe@mail.com', name: 'me' }],
      from: { email: 'edavid@yahoo.com', name: 'Wendy Harris', avatar: require('@/assets/images/avatars/2.png') },
      subject: 'Step face collection heart light cultural prepare.',
      cc: [],
      bcc: [],
      message:
        '<p>Suddenly man team would nor piece. Miss democratic receive.\nWindow measure drug success recent necessary group mission. Exist school under student rock trial treatment.\nRun season there social. Visit staff floor network improve home the.</p><p>Lay laugh sea sit food parent. Line move scientist floor establish like production. Decade PM exist moment.\nBeat under campaign say. Term gun local Congress democratic.</p><p>Chance poor attack far kitchen will. Appear thing also child whom manage hospital. Federal trouble fear between receive such involve here.\nSeek wife increase draw hair. Onto style minute democratic. Clearly music outside standard.</p></br>',
      attachments: [],
      isStarred: true,
      labels: ['important', 'private'],
      time: '2021-07-18T11:43:46',
      replies: [],
      folder: 'sent',
      isRead: true,
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Emails
// ------------------------------------------------
mock.onGet('/apps/email/emails').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', folder = 'inbox', label } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()

  function isInFolder(email) {
    if (folder === 'trash') return email.folder === 'trash'
    if (folder === 'starred') return email.isStarred && email.folder !== 'trash'

    return email.folder === (folder || email.folder) && email.folder !== 'trash'

    // email.folder === (folder || email.folder)

    // if (filter === 'important') return task.isImportant && !task.isDeleted
    // if (filter === 'completed') return task.isCompleted && !task.isDeleted
    // if (filter === 'deleted') return task.isDeleted
    // return !task.isDeleted
  }

  const filteredData = data.emails.filter(
    email =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (email.from.name.toLowerCase().includes(queryLowered) || email.subject.toLowerCase().includes(queryLowered)) &&
      isInFolder(email) &&
      (label ? email.labels.includes(label) : true),
  )
  /* eslint-enable  */

  // ------------------------------------------------
  // Email Meta
  // ------------------------------------------------
  const emailsMeta = {
    inbox: data.emails.filter(email => !email.isDeleted && !email.isRead && email.folder === 'inbox').length,
    draft: data.emails.filter(email => email.folder === 'draft').length,
    spam: data.emails.filter(email => !email.isDeleted && !email.isRead && email.folder === 'spam').length,
  }

  return [
    200,
    {
      emails: filteredData.reverse(),
      emailsMeta,
    },
  ]
})

// ------------------------------------------------
// POST: Update Email
// ------------------------------------------------
mock.onPost('/apps/email/update-emails').reply(config => {
  const { emailIds, dataToUpdate } = JSON.parse(config.data)

  function updateMailData(email) {
    Object.assign(email, dataToUpdate)
  }

  data.emails.forEach(email => {
    if (emailIds.includes(email.id)) updateMailData(email)
  })

  return [200]
})

// ------------------------------------------------
// POST: Update Emails Label
// ------------------------------------------------
mock.onPost('/apps/email/update-emails-label').reply(config => {
  const { emailIds, label } = JSON.parse(config.data)

  function updateMailLabels(email) {
    const labelIndex = email.labels.indexOf(label)

    if (labelIndex === -1) email.labels.push(label)
    else email.labels.splice(labelIndex, 1)
  }

  data.emails.forEach(email => {
    if (emailIds.includes(email.id)) updateMailLabels(email)
  })

  return [200]
})

// ------------------------------------------------
// GET: Paginate Existing Email
// ------------------------------------------------
mock.onGet('/apps/email/paginate-email').reply(config => {
  const { dir, emailId } = config.params

  const currentEmailIndex = data.emails.findIndex(e => e.id === emailId)

  const newEmailIndex = dir === 'previous' ? currentEmailIndex - 1 : currentEmailIndex + 1

  const newEmail = data.emails[newEmailIndex]

  return newEmail ? [200, newEmail] : [404]
})
