const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 2,
      searchType: 1,
      summaryHeaders: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'period',
        },
        {
          text: '',
          value: 'date',
        },
        {
          text: 'Accumulated Total',
          value: 'count',
        },
        {
          text: '',
          value: 'ratio',
        },
      ],
      summaryData: [
        {
          period: '・Today or Selected Date',
          date: '05/Oct/2021 (Tue)',
          count: 6.0,
          ratio: '',
        },
        {
          period: '・Same Date (Last Year)',
          date: '05/Oct/2020 (Mon)',
          count: 9.0,
          ratio: '37%',
        },
        {
          period: '・Same Week (Last Year)',
          date: '06/Oct/2020 (Tue)',
          count: 16.0,
          ratio: '23%',
        },
      ],
      text: '06/Oct/2021 (Tue)',
      image_src: '/images/mollImage/16/lauout.png',
      image_witdh: '970px',
      image_height: '2067px',
      seriesData: [
        {
          name: 'Ent 01',
          data: [
            50, 32, 21, 54, 10, 30, 44, 22, 66, 35, 32, 23, 34,
            42, 40, 0,
          ],
        },
        {
          name: 'Ent 02',
          data: [
            1050, 1032, 1021, 1054, 1010, 1030, 1044, 1022, 1066, 1035, 1032, 1023, 1034,
            1042, 1040, 0,
          ],
        },
        {
          name: 'Ent 03',
          data: [
            150, 232, 201, 154, 190, 330, 644, 322, 566, 335, 332, 123, 234,
            432, 410, 0,
          ],
        },
        {
          name: 'Ent 04',
          data: [
            320, 332, 301, 334, 390, 330, 320, 150, 232, 201, 154, 190, 111, 89,
            13, 44,
          ],
        },
        {
          name: 'Ent 05',
          data: [
            80, 132, 101, 234, 140, 110, 320, 301, 334, 390, 330, 320, 150, 24,
            53, 0,
          ],
        },
        {
          name: 'Ent 06',
          data: [
            20, 92, 91, 94, 120, 130, 120, 101, 234, 140, 110, 320, 91, 234,
            140, 110,
          ],
        },
        {
          name: 'Ent 07',
          data: [
            820, 932, 901, 934, 1290, '1330', 1320, 433, 22, 45, 222, 55, 666,
            777, 44, 22,
          ],
        },
        {
          name: 'Ent 08',
          data: [
            180, 132, 101, 134, 290, 130, 320, 345, 21, 45, 88, 55, 468, 55, 33,
            55,
          ],
        },
        {
          name: 'Ent 09',
          data: [
            320, 132, 701, 634, 790, 830, 620, 245, 563, 246, 764, 556, 445,
            774, 33, 77,
          ],
        },
        {
          name: 'Ent 10',
          data: [
            80, 92, 91, 94, 120, 130, 130, 88, 54, 22, 33, 44, 66, 77, 88, 23,
          ],
        },
        {
          name: '4F Ev A',
          data: [
            0, 2, 90, 93, 129, 133, 132, 64, 35, 66, 33, 32, 22, 56, 33, 456,
          ],
        },
        {
          name: '4F Esc 01',
          data: [
            820, 932, 32, 93, 129, 133, 132, 64, 35, 66, 33, 32, 1330, 1320,
            432, 16,
          ],
        },
        {
          name: '4F Ev B',
          data: [
            0, 2, 90, 93, 129, 133, 132, 64, 35, 66, 33, 32, 22, 56, 33, 456,
          ],
        },
        {
          name: '4F Esc 04',
          data: [
            0, 93, 90, 54, 120, 130, 132, 65, 34, 67, 35, 655, 34, 0, 644, 33,
          ],
        },
        {
          name: 'Ent 34',
          data: [
            80, 92, 12, 93, 120, 10, 120, 53, 33, 556, 23, 22, 45, 674, 22, 56,
          ],
        },
        {
          name: 'Ent 35',
          data: [
            82, 92, 91, 94, 10, 130, 10, 64, 345, 554, 335, 67, 443, 356, 445,
            23,
          ],
        },
        {
          name: '4F Ev C',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 55, 77, 89, 0,
          ],
        },
        {
          name: '4F Ev D',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 89, 0,
          ],
        },
        {
          name: '4F Esc 07',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 0,
          ],
        },
        {
          name: 'BF Ev A',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 33, 66, 33, 55, 77, 89, 0,
          ],
        },
        {
          name: 'BF Esc 01',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89,
          ],
        },
        {
          name: 'BF Ev B',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89,
          ],
        },
        {
          name: 'BF Esc 03',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 56, 33, 66, 33, 55, 77, 89, 0,
          ],
        },
        {
          name: 'BF Esc 05',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 33, 66, 33, 55, 77, 89, 0,
          ],
        },
        {
          name: 'BF Ev C',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 55, 77, 89, 0,
          ],
        },
        {
          name: 'BF Esc 07',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 89, 0,
          ],
        },
        {
          name: 'BF Ev D',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 0,
          ],
        },
        {
          name: 'BF Esc 08',
          data: [
            0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89,
          ],
        },
      ],
      legendData: [
        '08-09',
        '09-10',
        '10-11',
        '11-12',
        '12-13',
        '13-14',
        '14-15',
        '15-16',
        '16-17',
        '17-18',
        '18-19',
        '19-20',
        '20-21',
        '21-22',
        '22-23',
        '23-00',
      ],
      max80Data: [
        'Ent 04',
        'Ent 10',
        'Ent 07',
        'Ent 05',
        'BF Esc 01',
        'Ent 08',
        'Ent 09',
        'Ent 02',
        'BF Esc 08',
      ],
      pieData: [
        { value: 15153, name: 'Ent 04' },
        { value: 12531, name: 'Ent 10' },
        { value: 10191, name: 'Ent 07' },
        { value: 9159, name: 'Ent 05' },
        { value: 7757, name: 'BF Esc 01' },
        { value: 6965, name: 'Ent 08' },
        { value: 6528, name: 'Ent 09' },
        { value: 5914, name: 'Ent 02' },
        { value: 5146, name: 'BF Esc 08' },
        { value: 4220, name: 'Ent 01' },
        { value: 3653, name: 'Ent 03' },
        { value: 2223, name: '4F Esc 04' },
        { value: 1360, name: 'BF Ev B' },
        { value: 1310, name: 'BF Esc 03' },
        { value: 1265, name: 'BF Ev A' },
        { value: 1171, name: '4F Esc 07' },
        { value: 1053, name: '4F Esc 01' },
        { value: 842, name: 'BF Esc 05' },
        { value: 702, name: '4F Ev B' },
        { value: 4655, name: 'Other' },
      ],
      totalData: 101798,
      entranceData: [
        {
          id: '4429',
          positionX: '7.3%',
          positionY: '4.9%',
          count: '12,987',
          title: 'Ent 01',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',
        },
        {
          id: '4430',
          positionX: '22%',
          positionY: '5%',
          count: '123,987',
          title: 'Ent 02',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',
        },
      ],
      table1Data: [
        {
          time: '08-09',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '09-10',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '10-11',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '11-12',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '12-13',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '13-14',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '14-15',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '15-16',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '16-17',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '17-18',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '18-19',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '19-20',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '20-21',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '21-22',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '22-23',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '23-00',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
      ],
      table1FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
          count11: '12%',
          count12: '12%',
          count13: '12%',
          count14: '12%',
          count15: '12%',
          count16: '12%',
          count17: '12%',
          count18: '12%',
          count19: '12%',
        },
      ],
      table2Data: [
        {
          time: '08-09',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '09-10',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '10-11',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '11-12',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '12-13',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '13-14',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '14-15',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '15-16',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '16-17',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '17-18',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '18-19',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '19-20',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '20-21',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '21-22',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '22-23',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: '23-00',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
      ],
      table2FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
          count11: '12%',
        },
      ],
    },
    {
      id: 1,
      searchType: 2,
      text: '01/Nov/2021 - 13/Nov/2021',
      image_src: '/images/mollImage/16/lauout.png',
      image_witdh: '970px',
      image_height: '2067px',
      seriesData: [
        {
          name: 'This Month',
          data: [
            120, 1132, 1011, 134, 90, 230, 210, 1114, 655, 444, 333, 222, 144,
          ],
        },
        {
          name: 'Last Year Same Day',
          data: [
            220, 182, 1911, 234, 290, 330, 310, 2112, 345, 67, 43, 23, 445,
          ],
        },
        {
          name: 'Last Year Same Date',
          data: [
            150, 232, 201, 154, 190, 330, 644, 3212, 5616, 1335, 332, 123, 234,
          ],
        },
      ],
      legendData: [
        '01/Nov/2021',
        '02/Nov/2021',
        '03/Nov/2021',
        '04/Nov/2021',
        '05/Nov/2021',
        '06/Nov/2021',
        '07/Nov/2021',
        '08/Nov/2021',
        '09/Nov/2021',
        '10/Nov/2021',
        '11/Nov/2021',
        '12/Nov/2021',
        '13/Nov/2021',
      ],
      max80Data: [
        'Ent 04',
        'Ent 10',
        'Ent 07',
        'Ent 05',
        'BF Esc 01',
        'Ent 08',
        'Ent 09',
        'Ent 02',
        'BF Esc 08',
      ],
      pieData: [
        { value: 15153, name: 'Ent 04' },
        { value: 12531, name: 'Ent 10' },
        { value: 10191, name: 'Ent 07' },
        { value: 9159, name: 'Ent 05' },
        { value: 7757, name: 'BF Esc 01' },
        { value: 6965, name: 'Ent 08' },
        { value: 6528, name: 'Ent 09' },
        { value: 5914, name: 'Ent 02' },
        { value: 5146, name: 'BF Esc 08' },
        { value: 4220, name: 'Ent 01' },
        { value: 3653, name: 'Ent 03' },
        { value: 2223, name: '4F Esc 04' },
        { value: 1360, name: 'BF Ev B' },
        { value: 1310, name: 'BF Esc 03' },
        { value: 1265, name: 'BF Ev A' },
        { value: 1171, name: '4F Esc 07' },
        { value: 1053, name: '4F Esc 01' },
        { value: 842, name: 'BF Esc 05' },
        { value: 702, name: '4F Ev B' },
        { value: 4655, name: 'other' },
      ],
      totalData: 101798,
      table1Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
      ],
      table1FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
          count11: '12%',
          count12: '12%',
          count13: '12%',
          count14: '12%',
          count15: '12%',
          count16: '12%',
          count17: '12%',
          count18: '12%',
          count19: '12%',
        },
      ],
      table2Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
        },
      ],
      table2FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: '123',
          count3: '222',
          count4: '343',
          count5: '422',
          count6: '432',
          count7: '343',
          count8: '343',
          count9: '343',
          count10: '343',
          count11: '343',
          count12: '343',
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
          count11: '12%',
          count12: '12%',
        },
      ],
      holidayData: [
        '11/Nov/2021(Thu)',
      ],
      commentTableData: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: ' visit AEO N MALL',
          count7: '',
          count8: '',
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
      ],
      commentFooterData: [
        {
          time: 'Total',
          count1: '611,224',
          count2: 355,
          count3: 355,
          count4: '',
          count5: '',
          count6: '',
          count7: '',
          count8: '',
        },
      ],
      table3Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
      ],
      table3FooterData: [
        {
          time: 'Total',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
          count11: '12%',
          count12: '12%',
          count13: '12%',
          count14: '12%',
          count15: '12%',
          count16: '12%',
          count17: '12%',
          count18: '100%',
        },
      ],
      entranceData: [
        {
          id: '4429',
          positionX: '7.3%',
          positionY: '4.9%',
          count: '12,987',
          title: 'Ent 01',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',
        },
        {
          id: '4430',
          positionX: '22%',
          positionY: '5%',
          count: '123,987',
          title: 'Ent 02',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',
        },
      ],
    },
    {
      id: 3,
      searchType: 3,
      text: '2019 - 2021',
      image_src: '/images/mollImage/16/lauout.png',
      image_witdh: '970px',
      image_height: '2067px',
      seriesData: [
        {
          data: [
            120000, 113002, 5202,
          ],
        },
      ],
      legendData: [
        '2019',
        '2020',
        '2021',
      ],
      pieData: [
        { value: 15153, name: '2019' },
        { value: 12531, name: '2020' },
        { value: 9191, name: '2021' },
      ],
      totalData: 31798,
      table1Data: [
        {
          time: '2019',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '2020',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '2021',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
      ],
      table1FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
          count11: '12%',
          count12: '12%',
          count13: '12%',
          count14: '12%',
          count15: '12%',
          count16: '12%',
          count17: '12%',
          count18: '12%',
          count19: '12%',
        },
      ],
      table2Data: [
        {
          time: '2019',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
        },
        {
          time: '2020',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
        },
        {
          time: '2022',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
        },
      ],
      table2FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: '123',
          count3: '222',
          count4: '343',
          count5: '422',
          count6: '432',
          count7: '343',
          count8: '343',
          count9: '343',
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
        },
      ],
      commentTableData: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: ' visit AEO N MALL',
          count7: '',
          count8: '',
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: '',
          count7: '',
          count8: '',
        },
      ],
      commentFooterData: [
        {
          time: 'Total',
          count1: '611,224',
          count2: 355,
          count3: 355,
          count4: '',
          count5: '',
          count6: '',
          count7: '',
          count8: '',
        },
      ],
      table3Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
      ],
      table3FooterData: [
        {
          time: 'Total',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 355,
          count11: 355,
          count12: 355,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
        },
        {
          time: 'Ratio',
          count1: '12%',
          count2: '12%',
          count3: '12%',
          count4: '12%',
          count5: '12%',
          count6: '12%',
          count7: '12%',
          count8: '12%',
          count9: '12%',
          count10: '12%',
          count11: '12%',
          count12: '12%',
          count13: '12%',
          count14: '12%',
          count15: '12%',
          count16: '12%',
          count17: '12%',
          count18: '100%',
        },
      ],
      entranceData: [
        {
          id: '4429',
          positionX: '7.3%',
          positionY: '4.9%',
          count: '12,987',
          title: 'Ent 01',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',
        },
        {
          id: '4430',
          positionX: '22%',
          positionY: '5%',
          count: '123,987',
          title: 'Ent 02',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/entrance\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
