const apps = [
  {
    path: '/apps/entrance',
    name: 'apps-entrance',
    component: () => import('@/views/apps/entrance/Entrance.vue'),
    meta: {
      layout: 'content',
      resource: 'Entrance',
    },
  },
  {
    path: '/apps/corridor',
    name: 'apps-corridor',
    component: () => import('@/views/apps/corridor/Corridor.vue'),
    meta: {
      layout: 'content',
      resource: 'Corridor',
    },
  },
  {
    path: '/apps/toilet',
    name: 'apps-toilet',
    component: () => import('@/views/apps/toilet/Toilet.vue'),
    meta: {
      layout: 'content',
      resource: 'Toilet',
    },
  },
  {
    path: '/apps/parking',
    name: 'apps-parking',
    component: () => import('@/views/apps/parking/Parking.vue'),
    meta: {
      layout: 'content',
      resource: 'PeopleCarBike',
    },
  },
  {
    path: '/apps/combined',
    name: 'apps-combined',
    component: () => import('@/views/apps/combined/Combined.vue'),
    meta: {
      layout: 'content',
      resource: 'Combined',
    },
  },
  {
    path: '/apps/stay',
    name: 'apps-stay',
    component: () => import('@/views/apps/stay/Stay.vue'),
    meta: {
      layout: 'content',
      resource: 'Staying',
    },
  },
  {
    path: '/apps/comparison',
    name: 'apps-comparison',
    component: () => import('@/views/apps/comparison/Comparison.vue'),
    meta: {
      layout: 'content',
      resource: 'Comparison',
    },
  },
  {
    path: '/apps/search',
    name: 'apps-search',
    component: () => import('@/views/apps/search/Search.vue'),
    meta: {
      layout: 'content',
      resource: 'Search',
    },
  },
  {
    path: '/apps/memobox',
    name: 'apps-memobox',
    component: () => import('@/views/apps/memobox/Memobox.vue'),
    meta: {
      layout: 'content',
      resource: 'Memobox',
    },
  },
  {
    path: '/apps/csvDownload',
    name: 'apps-csvDownload',
    component: () => import('@/views/apps/csvDownload/CsvDownload.vue'),
    meta: {
      layout: 'content',
      resource: 'CSVDownload',
    },
  },
  {
    path: '/apps/option',
    name: 'apps-option',
    component: () => import('@/views/apps/option/Option.vue'),
    meta: {
      layout: 'content',
      resource: 'Option',
    },
  },
  {
    path: '/apps/domesticCsvDownload',
    name: 'apps-domesticCsvDownload',
    component: () => import('@/views/apps/csvDownload/DomesticCsvDownload.vue'),
    meta: {
      layout: 'content',
      resource: 'DomesticCSVDownload',
    },
  },
  {
    path: '/apps/event',
    name: 'apps-event',
    component: () => import('@/views/apps/event/Eventbox.vue'),
    meta: {
      layout: 'content',
      resource: 'Event',
    },
  },

  // {
  //   path: '/apps/setEvent',
  //   name: 'apps-setEvent',
  //   component: () => import('@/views/apps/setEvent/SetEvent.vue'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'SetEvent',
  //   },
  // },
  {
    path: '/apps/gifPlayer',
    name: 'apps-gifPlayer',
    component: () => import('@/views/apps/gifPlayer/GifPlayer.vue'),
    meta: {
      layout: 'blank',
      resource: 'GifPlayer',
    },
  },
  {
    path: '/apps/vision',
    name: 'apps-vision',
    component: () => import('@/views/apps/enterShop/Vision.vue'),
    meta: {
      layout: 'blank',
      resource: 'Vision',
    },
  },
  {
    path: '/apps/traffic',
    name: 'apps-traffic',
    component: () => import('@/views/apps/traffic/Traffic.vue'),
    meta: {
      layout: 'content',
      resource: 'Traffic',
    },
  },
  {
    path: '/apps/exportCsv',
    name: 'apps-exportCsv',
    component: () => import('@/views/apps/exportCsv/ExportCsv.vue'),
    meta: {
      layout: 'content',
      resource: 'ExportCsv',
    },
  },
  {
    path: '/apps/importCsv',
    name: 'apps-importCsv',
    component: () => import('@/views/apps/importCsv/ImportCsv.vue'),
    meta: {
      layout: 'content',
      resource: 'ImportCsv',
    },
  },
  {
    path: '/apps/user',
    name: 'apps-user',
    component: () => import('@/views/apps/user/UserList.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
    },
  },
  {
    path: '/apps/sendReport',
    name: 'apps-sendReport',
    component: () => import('@/views/apps/sendReport/SendReport.vue'),
    meta: {
      layout: 'content',
      resource: 'SendReport',
    },
  },
  {
    path: '/apps/home',
    name: 'apps-home',
    component: () => import('@/views/apps/home/Home.vue'),
    meta: {
      layout: 'content',
      resource: 'Home',
    },
  },
  {
    path: '/apps/enterShop',
    name: 'apps-enterShop',
    component: () => import('@/views/apps/enterShop/EnterShop.vue'),
    meta: {
      layout: 'content',
      resource: 'EnterShop',
    },
  },
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
]

export default apps
