const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 1,
      text: '01/Nov/2021 - 13/Nov/2021',
      seriesData: [
        {
          name: 'Count',
          data: [
            120, 1132, 1011, 134, 90, 230, 210, 1114, 655, 444, 333, 222, 144,
          ],
        },
        {
          name: 'Last Year',
          data: [
            220, 182, 1911, 234, 290, 330, 310, 2112, 345, 67, 43, 23, 445,
          ],
        },
      ],
      legendData: [
        '01/Nov/2021 00-01',
        '01/Nov/2021 01-02',
        '01/Nov/2021 02-03',
        '01/Nov/2021 03-04',
        '01/Nov/2021 04-05',
        '01/Nov/2021 05-06',
        '01/Nov/2021 06-07',
        '01/Nov/2021 07-08',
        '01/Nov/2021 08-09',
        '01/Nov/2021 09-10',
        '01/Nov/2021 10-11',
        '01/Nov/2021 11-12',
        '01/Nov/2021 12-13',
        '01/Nov/2021 13-14',
        '01/Nov/2021 14-15',
        '01/Nov/2021 23-00',
      ],
      entranceSearch1Data: [
        {
          time: '01/Nov/2021 00-01',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 01-02',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 02-03',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 03-04',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 04-05',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 05-06',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 06-07',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 07-08',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 08-09',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 09-10',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 10-11',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 11-12',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 12-13',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 13-14',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 14-15',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 15-16',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 16-17',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 17-18',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 18-19',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 19-20',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 20-21',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 21-22',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 22-23',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '01/Nov/2021 23-00',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
      ],
    },
    {
      id: 2,
      text: '01/Nov/2021 - 13/Nov/2021',
      seriesData: [
        {
          name: 'Count',
          data: [
            120, 1132, 1011, 134,
          ],
        },
        {
          name: 'Last Year',
          data: [
            220, 182, 1911, 234,
          ],
        },
      ],
      legendData: [
        '01/Nov/2021',
        '02/Nov/2021',
        '03/Nov/2021',
        '04/Nov/2021',
      ],
      entranceSearch1Data: [
        {
          time: '01/Nov/2021',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '02/Nov/2021',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '03/Nov/2021',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
        {
          time: '04/Nov/2021',
          dayOfTheWeek: 'Mon',
          count: 123,
          lastYear: 222,
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/entranceSearch1\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
