<template>
  <div>
    <v-row
      justify="left"
      align="center"
      no-gutters
      class="flex-nowrap"
    >
      <vue-date-picker
        v-model="dateRange"
        :shortcuts="shortcutsShow ? shortcuts:shortcutsEmpty"
        type="date"
        format="YYYY/MM/DD"
        :lang="lang"
        :clearable="false"
        :range="isRange"
        @input="handleChange"
      ></vue-date-picker>
      &nbsp;
      <v-btn
        v-if="searchBtnShow"
        color="info"
        class="date-range-picker-search search-button"
        style="text-transform: none"
        @click="search"
      >
        <v-icon>
          {{ icons.mdiMagnify }}
        </v-icon>
        <div>検索</div>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import VueDatePicker from 'vue2-datepicker'
import { mdiMagnify, mdiCalendarMonth } from '@mdi/js'
import 'vue2-datepicker/index.css'

// import 'vue2-datepicker/locale/ja'
import moment from 'moment'
import { isEmpty } from '@core/utils'
import { ref } from '@vue/composition-api'

moment.locale('ja')

export default {
  components: { VueDatePicker },
  props: {
    shortcutsShow: {
      type: Boolean,
      default: true,
    },
    searchBtnShow: {
      type: Boolean,
      default: true,
    },
    isRange: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const start = sessionStorage.getItem('startDate')
    const end = sessionStorage.getItem('endDate')
    let startDate
    let endDate
    if (!isEmpty(start)) {
      startDate = new Date(start)
      endDate = new Date(end)
    } else {
      const currentDate = new Date()
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth().toString().padStart(2, '0'), currentDate.getDate().toString().padStart(2, '0'))
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth().toString().padStart(2, '0'), currentDate.getDate().toString().padStart(2, '0'))
    }

    const dateRange = ref([])
    if (props.isRange) {
      dateRange.value = [startDate, endDate]
    } else {
      dateRange.value = startDate
    }

    const handleChange = date => {
      sessionStorage.setItem('startDate', date[0])
      sessionStorage.setItem('endDate', date[1])
      dateRange.value = date
    }

    const search = () => {
      context.emit('dataSearch')
    }

    const defaultValue = [startDate, endDate]

    return {
      dateRange,
      defaultValue,
      icons: {
        mdiMagnify,
        mdiCalendarMonth,
      },
      lang: {
        days: ['日', '月', '火', '水', '木', '金', '土'],
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        yearFormat: 'YYYY年',
        monthFormat: 'MM月',
      },
      isEmpty,
      shortcutsEmpty: [],
      shortcuts: [
        {
          text: '今日',
          onClick: () => [new Date(), new Date()],
        },
        {
          text: '昨日',
          onClick: () => [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
        },
        {
          text: '前の日',
          onClick: () => {
            const date = new Date(dateRange.value[0])
            console.log(date)

            return [moment(date).subtract(1, 'days').toDate(), moment(date).subtract(1, 'days').toDate()]
          },
        },
        {
          text: '後の日',
          onClick: () => {
            const date = new Date(dateRange.value[0])

            return [moment(date).add(1, 'days').toDate(), moment(date).add(1, 'days').toDate()]
          },
        },
        {
          text: '先週',
          onClick: () => [moment().subtract(1, 'week').startOf('week').toDate(), moment(new Date()).subtract(1, 'week').endOf('week').toDate()],
        },
        {
          text: '先月',
          onClick: () => [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
        },

        // {
        //   text: 'Last Year',
        //   onClick: () => [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()],
        // },
      ],
      handleChange,
      search,
    }
  },
}
</script>
<style lang="scss">
.date-range-picker-search {
  &.range-input {
    background: #fff;
    cursor: pointer;
    padding: 5px 10px;
    width: 100%;
    border: 2px map-get($theme-colors, 'info') solid;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
  }
}
</style>
<style lang="scss" scoped>
.date-range-picker-search {
  &.search-button {
    border-radius: 5px;
  }
}
</style>
<style>
.mx-table-date td:last-child {
  color: #0f52ba !important;
}
.mx-table-date td:first-child {
  color: #f00 !important;
}
.mx-datepicker-main {
  font-family: 'Arial', sans-serif;
  font-size: 12px;
}
</style>
