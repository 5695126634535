const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 2,
      searchType: 1,
      text: '06/Oct/2021 (Tue)',
      image_src: '/images/mollImage/16/toiletbk.png',
      image_witdh: '980px',
      image_height: '712px',
      seriesData1: [
        {
          name: 'M',
          data: [
            50, 32, 21, 54, 10, 30, 44, 22, 66, 35, 32, 23, 34,
            42, 40, 0,
          ],
        },
        {
          name: 'F',
          data: [
            1050, 1032, 1021, 1054, 1010, 1030, 1044, 1022, 1066, 1035, 1032, 1023, 1034,
            1042, 1040, 0,
          ],
        },
        {
          name: 'Total',
          data: [
            150, 232, 201, 154, 190, 330, 644, 322, 566, 335, 332, 123, 234,
            432, 410, 0,
          ],
        },
      ],
      legendData1: [
        '08-09',
        '09-10',
        '10-11',
        '11-12',
        '12-13',
        '13-14',
        '14-15',
        '15-16',
        '16-17',
        '17-18',
        '18-19',
        '19-20',
        '20-21',
        '21-22',
        '22-23',
        '23-00',
      ],
      seriesData2: [
        {
          name: 'M',
          data: [
            50, 32, 21, 54, 10, 30, 44, 22, 66, 35, 32, 23, 34,
            42, 40, 0,
          ],
        },
        {
          name: 'F',
          data: [
            150, 132, 121, 154, 110, 130, 144, 122, 166, 135, 132, 123, 134,
            142, 140, 0,
          ],
        },
        {
          name: 'Total',
          data: [
            250, 264, 142, 208, 120, 160, 188, 144, 232, 170, 164, 146, 168,
            183, 180, 0,
          ],
        },
      ],
      legendData2: [
        '1F',
        '2F',
        '3F',
        'BF',
      ],
      pieData: [
        { value: 15153, name: 'WC 01' },
        { value: 12531, name: 'WC 02' },
        { value: 10191, name: 'WC 03' },
        { value: 9159, name: 'WC 04' },
        { value: 7757, name: 'WC 05' },
        { value: 6965, name: 'WC 06' },
        { value: 6528, name: 'WC 07' },
        { value: 5914, name: 'WC 08' },
        { value: 5146, name: 'WC 09' },
        { value: 4220, name: 'WC 10' },
        { value: 3653, name: 'WC 11' },
        { value: 2223, name: 'WC 12' },
        { value: 1360, name: 'WC 13' },
      ],
      totalData: 101798,
      table1Data: [
        {
          time: '08-09',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '09-10',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '10-11',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '11-12',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '12-13',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '13-14',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '14-15',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '15-16',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '16-17',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '17-18',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '18-19',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '19-20',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '20-21',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '21-22',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '22-23',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '23-00',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
      ],
      table1FooterData: [
        {
          time: 'SubTTL',
          count1: '8,668',
          count2: '10,882',
          count3: '2,402',
          count4: '3,236',
          count5: '1,490',
          count6: '2,043',
          count7: '2,659',
          count8: '3,136',
          count9: '2,145',
          count10: '2,153',
          count11: '1,012',
          count12: '1,433',
          count13: '1,424',
          count14: '1,958',
          count15: '1,452',
          count16: '1,571',
          count17: '778',
          count18: '1,209',
          count19: '875',
          count20: '1,000',
        },
        {
          time: 'Total',
          count1: '19,550',
          count2: '19,550',
          count3: '2,402',
          count4: '3,236',
          count5: '3,533',
          count6: '3,533',
          count7: '5,795',
          count8: '5,795',
          count9: '2,145',
          count10: '2,153',
          count11: '2,445',
          count12: '2,445',
          count13: '3,382',
          count14: '3,382',
          count15: '1,452',
          count16: '1,571',
          count17: '1,987',
          count18: '1,987',
          count19: '1,875',
          count20: '1,875',
        },
      ],
      table2Data: [
        {
          time: '08-09',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '09-10',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '10-11',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '11-12',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '12-13',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '13-14',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '14-15',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '15-16',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '16-17',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '17-18',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '18-19',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '19-20',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '20-21',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '21-22',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '22-23',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '23-00',
          count1: '12,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
      ],
      table2FooterData: [
        {
          time: 'Total',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
      ],
    },
    {
      id: 1,
      searchType: 2,
      text: '01/Nov/2021 - 13/Nov/2021',
      image_src: '/images/mollImage/16/toiletbk.png',
      image_witdh: '980px',
      image_height: '712px',
      seriesData1: [
        {
          name: 'M',
          data: [
            50, 32, 21, 54, 10, 30, 44, 22, 66, 35, 32, 23, 34,
          ],
        },
        {
          name: 'F',
          data: [
            100, 200, 121, 154, 110, 130, 144, 122, 166, 135, 132, 123, 134,
          ],
        },
        {
          name: 'Total',
          data: [
            150, 232, 201, 154, 190, 330, 644, 322, 566, 335, 332, 123, 234,
          ],
        },
      ],
      legendData1: [
        '01/Nov/2021',
        '02/Nov/2021',
        '03/Nov/2021',
        '04/Nov/2021',
        '05/Nov/2021',
        '06/Nov/2021',
        '07/Nov/2021',
        '08/Nov/2021',
        '09/Nov/2021',
        '10/Nov/2021',
        '11/Nov/2021',
        '12/Nov/2021',
        '13/Nov/2021',
      ],
      seriesData2: [
        {
          name: 'M',
          data: [
            50, 32, 21, 54, 10, 30, 44, 22, 66, 35, 32, 23, 34,
            42, 40, 0,
          ],
        },
        {
          name: 'F',
          data: [
            150, 132, 121, 154, 110, 130, 144, 122, 166, 135, 132, 123, 134,
            142, 140, 0,
          ],
        },
        {
          name: 'Total',
          data: [
            250, 264, 142, 208, 120, 160, 188, 144, 232, 170, 164, 146, 168,
            183, 180, 0,
          ],
        },
      ],
      legendData2: [
        '1F',
        '2F',
        '3F',
        'BF',
      ],
      pieData: [
        { value: 15153, name: 'WC 01' },
        { value: 12531, name: 'WC 02' },
        { value: 10191, name: 'WC 03' },
        { value: 9159, name: 'WC 04' },
        { value: 7757, name: 'WC 05' },
        { value: 6965, name: 'WC 06' },
        { value: 6528, name: 'WC 07' },
        { value: 5914, name: 'WC 08' },
        { value: 5146, name: 'WC 09' },
        { value: 4220, name: 'WC 10' },
        { value: 3653, name: 'WC 11' },
        { value: 2223, name: 'WC 12' },
        { value: 1360, name: 'WC 13' },
      ],
      totalData: 101798,
      table1Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
        },
      ],
      table1FooterData: [
        {
          time: 'SubTTL',
          count1: '8,668',
          count2: '10,882',
          count3: '2,402',
          count4: '3,236',
          count5: '1,490',
          count6: '2,043',
          count7: '2,659',
          count8: '3,136',
          count9: '2,145',
          count10: '2,153',
          count11: '1,012',
          count12: '1,433',
          count13: '1,424',
          count14: '1,958',
          count15: '1,452',
          count16: '1,571',
          count17: '778',
          count18: '1,209',
          count19: '875',
          count20: '1,000',
        },
        {
          time: 'Total',
          count1: '19,550',
          count2: '19,550',
          count3: '2,402',
          count4: '3,236',
          count5: '3,533',
          count6: '3,533',
          count7: '5,795',
          count8: '5,795',
          count9: '2,145',
          count10: '2,153',
          count11: '2,445',
          count12: '2,445',
          count13: '3,382',
          count14: '3,382',
          count15: '1,452',
          count16: '1,571',
          count17: '1,987',
          count18: '1,987',
          count19: '1,875',
          count20: '1,875',
        },
      ],
      table2Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 159,
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
        },
      ],
      holidayData: [
        '11/Nov/2021(Thu)',
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/toilet\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
