const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 1,
      searchType: 2,
      text: '01/Nov/2021 - 13/Nov/2021',
      seriesData: [
        {
          name: 'Count',
          data: [
            120, 1132, 1011, 134, 90, 230, 210, 1114, 655, 444, 333, 222, 144,
          ],
        },
        {
          name: 'Compare',
          data: [
            220, 182, 1911, 234, 290, 330, 310, 2112, 345, 67, 43, 23, 445,
          ],
        },
      ],
      legendData: [
        '01/Nov/2021',
        '02/Nov/2021',
        '03/Nov/2021',
        '04/Nov/2021',
        '05/Nov/2021',
        '06/Nov/2021',
        '07/Nov/2021',
        '08/Nov/2021',
        '09/Nov/2021',
        '10/Nov/2021',
        '11/Nov/2021',
        '12/Nov/2021',
        '13/Nov/2021',
      ],
      entranceSearch2Data: [
        {
          timeA: '25/Dec/2021 00-01',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 00-01',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 01-02',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 01-02',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 02-03',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 02-03',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 03-04',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 03-04',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 04-05',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 04-05',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 05-06',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 05-06',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 06-07',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 06-07',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 07-08',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 07-08',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 08-09',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 08-09',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 09-10',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 09-10',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 10-11',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 10-11',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 11-12',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 11-12',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 12-13',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 12-13',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 13-14',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 13-14',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 14-15',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 14-15',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 15-16',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 15-16',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 16-17',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 16-17',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 17-18',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 17-18',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 18-19',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 18-19',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 19-20',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 19-20',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 20-21',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 20-21',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 21-22',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 21-22',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 22-23',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 22-23',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
        {
          timeA: '25/Dec/2021 23-00',
          dayOfTheWeekA: 'Sat',
          count: 123,
          timeB: '25/Dec/2021 23-00',
          dayOfTheWeekB: 'Sat',
          compare: '123',
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/entranceSearch2\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
